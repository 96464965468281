import PropTypes from 'prop-types';
import { ResponsiveImage, Link } from '..';
import classNames from 'classnames';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { Pagination } from '@egjs/flicking-plugins';
import Location from '@/assets/location.svg';
import LodgeCard from './LodgeCard';
import tailwindConfig from '@/tailwind.config.js';
import { fixColor } from '@/utils';

const LodgeCardLarge = ({ data, className, backgroundColour, ...props }) => {
  const _plugins = data.images.length > 1 ? [new Pagination({ type: 'bullet' })] : [];
  const backgroundColor = fixColor(backgroundColour) || tailwindConfig.theme.colors.white;
  const dark = backgroundColor.toLowerCase() === tailwindConfig.theme.colors.white.toLowerCase();

  return (
    <>
      <div
        className={classNames(
          'hidden w-full gap-s bg-off-white p-xxs pl-10 transition-colors duration-500 lg:flex lg:gap-10',
          dark ? 'hover:bg-tan' : 'hover:bg-white',
          className,
        )}
        {...props}
      >
        <Link link={data.primaryCta} className="flex w-4/12 flex-col justify-between py-8">
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-3">
              {data.preHeading && (
                <p className="text-small-paragraph font-n-light not-italic leading-desktop-small text-black/50">
                  {data.preHeading}
                </p>
              )}

              {data.headingTitle && (
                <h1 className="font-larken text-desktop-small font-normal not-italic leading-desktop-small text-black">
                  {data.headingTitle}
                </h1>
              )}
            </div>
            {data.description && (
              <p
                className="text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
          </div>
          {data.keyFeatures.length > 0 && (
            <div className="mx-xxs grid grid-cols-2 border-t border-dashed border-black/25">
              {data.keyFeatures.map((feature, index) => {
                return index < 4 ? (
                  <div key={index} className="border-b border-dashed border-black/25 py-xs">
                    <div className="pl-xxs font-gotham text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75 before:mr-2.5 before:content-facility">
                      {feature}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </Link>
        <div className="relative w-8/12">
          <div className="image-wrapper relative aspect-[821/563] w-full">
            <Flicking
              align="center"
              bound
              preventDefaultOnDrag
              className="h-full"
              circular={data.images.length > 1}
              plugins={_plugins}
              bounce={data.images.length === 1 ? 0 : '20%'}
            >
              {data.images.map((image, index) => (
                <Link key={index} className="w-full" link={data.primaryCta}>
                  <ResponsiveImage
                    image={image}
                    widths={{ xs: 339, md: 299, lg: 741, xl: 848, xxl: 992 }}
                    heights={{ xs: 219, md: 193, lg: 508, xl: 582, xxl: 680 }}
                    className={classNames(data.images.length > 1 && 'cursor-grab')}
                  />
                </Link>
              ))}
              {data.images.length > 1 && (
                <ViewportSlot>
                  <div className="flicking-pagination !bottom-3 [&_.flicking-pagination-bullet.flicking-pagination-bullet-active]:bg-bright-orange [&_.flicking-pagination-bullet]:border [&_.flicking-pagination-bullet]:border-white/50 [&_.flicking-pagination-bullet]:bg-white/50"></div>
                </ViewportSlot>
              )}
            </Flicking>

            {data.label && data.locationPage && (
              <Link
                link={data.locationPage}
                className="absolute left-4 top-4 z-10 flex items-center gap-xs rounded-full bg-black/25 px-xs py-3 backdrop-blur-[22px] transition-all duration-700 hover:bg-bright-orange"
              >
                <Location className="fill-white" />
                <p className="text-btn font-n-light not-italic leading-tiny-paragraph text-white">{data.label}</p>
              </Link>
            )}
          </div>
        </div>
      </div>
      <LodgeCard
        data={data}
        backgroundColour={backgroundColour}
        className={classNames('lg:hidden', className)}
        {...props}
      />
    </>
  );
};

LodgeCardLarge.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LodgeCardLarge;
