import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Plus from '../../assets/plus.svg';
import Minus from '../../assets/minus.svg';
import { useTranslation } from 'next-i18next';

const NumberInput = ({ name, value, onChange, className, minValue = 0, maxValue = Infinity, ...props }) => {
  const [_value, set_value] = useState(value);
  const { t } = useTranslation('common');

  const _onChange = (add) => {
    const newValue = _value + +add;
    set_value(newValue);
    const event = { target: { name, value: newValue } };
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== null && value !== undefined) {
      set_value(value);
    }
  }, [value]);

  if (!value) {
    value = minValue;
  }

  return (
    <div className={classNames('number-input items-center justify-between px-4 py-2', className)} {...props}>
      <button
        className="flex h-6 w-6 items-center justify-center"
        disabled={_value <= minValue || props.disabled}
        aria-label={t('general.$decrease')}
        onClick={(e) => {
          e.preventDefault();
          _onChange(-1);
        }}
      >
        <Minus className={classNames('h-5 w-5 fill-current', _value <= minValue && 'fill-grey3')} />
      </button>
      <input
        type="number"
        name={name}
        min={minValue}
        value={_value}
        autoComplete="off"
        disabled={props.disabled}
        className={classNames('mx-6', _value > minValue && 'is-value')}
        onChange={(e) => {
          e.preventDefault();
          e.target.value = +e.target.value;
          onChange(e);
        }}
      />

      <button
        className="flex h-6 w-6 items-center justify-center"
        disabled={_value >= maxValue || props.disabled}
        aria-label={t('general.$increase')}
        onClick={(e) => {
          e.preventDefault();
          _onChange(1);
        }}
      >
        <Plus className={classNames('h-5 w-5 fill-current', _value >= maxValue && 'fill-grey3')} />
      </button>
    </div>
  );
};

export default NumberInput;
