import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { default as RDatepicker, registerLocale } from 'react-datepicker';
import { enUS, fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import JumpLeft from '../../assets/jumpLeft.svg';
import ChevronRight from '../../assets/chevronRight.svg';
import { useTranslation } from 'next-i18next';
import { PopoverMenu } from '../index';

registerLocale('en-US', enUS);
registerLocale('fr', fr);

const Datepicker = ({
  name,
  value,
  onChange,
  className,
  placeholder,
  selectsRange,
  dateFormat = 'MM/dd/yyyy',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [_value, set_value] = useState(value);
  const router = useRouter();
  const { t } = useTranslation('common');

  const _onChange = (value) => {
    if (selectsRange) {
      if (value[0] && value[1]) {
        setOpen(false);
      }
    } else {
      setOpen(false);
      set_value(value);
    }

    if (onChange) {
      const event = { target: { name, value: value } };
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== null && value !== undefined) {
      set_value(value);
    }
  }, [value]);

  const prevent = (e, fn) => {
    e.preventDefault();
    fn(e);
  };

  const customHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => (
    <div className="flex justify-between">
      <div className="flex gap-4">
        <button
          onClick={(e) => prevent(e, decreaseYear)}
          disabled={prevYearButtonDisabled}
          aria-label={t('general.$previous')}
        >
          <JumpLeft className={classNames(prevYearButtonDisabled ? 'stroke-black/25' : 'stroke-black')} />
        </button>

        <button
          onClick={(e) => prevent(e, decreaseMonth)}
          disabled={prevMonthButtonDisabled}
          aria-label={t('general.$previous')}
        >
          <ChevronRight
            className={classNames(prevMonthButtonDisabled ? 'stroke-black/25' : 'stroke-black', 'rotate-180')}
          />
        </button>
      </div>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString(router.locale, {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <div className="flex gap-4">
        <button
          onClick={(e) => prevent(e, increaseMonth)}
          disabled={nextMonthButtonDisabled}
          aria-label={t('general.$next')}
        >
          <ChevronRight className={classNames(nextMonthButtonDisabled ? 'stroke-black/25' : 'stroke-black')} />
        </button>
        <button
          onClick={(e) => prevent(e, increaseYear)}
          disabled={nextYearButtonDisabled}
          aria-label={t('general.$next')}
        >
          <JumpLeft className={classNames(nextYearButtonDisabled ? 'stroke-black/25' : 'stroke-black', 'rotate-180')} />
        </button>
      </div>
    </div>
  );

  const valueStr = selectsRange
    ? props.startDate || props.endDate
      ? `${(props.startDate && format(props.startDate, dateFormat)) || ''} - ${
          (props.endDate && format(props.endDate, dateFormat)) || ''
        }`
      : null
    : _value && format(_value, dateFormat);

  return (
    <PopoverMenu
      open={open}
      title={valueStr ? valueStr : placeholder}
      titleClass={!valueStr && placeholder && 'placeholder'}
      onOpen={() => setOpen(true)}
      className={classNames(className, 'datepicker', _value && 'selected')}
    >
      <RDatepicker
        selected={_value}
        onChange={_onChange}
        locale={router.locale}
        startDate={new Date()}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        name={name}
        showDisabledMonthNavigation
        renderCustomHeader={customHeader}
        inline
        selectsRange={selectsRange}
        {...props}
      />
    </PopoverMenu>
  );
};

export default Datepicker;
