import { ResponsiveImage, Box } from '@/components';
import classNames from 'classnames';
import { listingGridItem } from '@/animations';

const MediaMasonryCard = ({ image, widths, heights, className, ...props }) => {
  return (
    <Box
      motion={listingGridItem}
      className={classNames('group relative size-full cursor-pointer', className)}
      {...props}
    >
      <ResponsiveImage image={image} widths={widths} heights={heights} className="size-full object-cover" />
      <div
        className="absolute left-0 top-0 size-full"
        style={{
          background:
            'linear-gradient(179deg, rgba(0, 0, 0, 0.40)-2.24%, rgba(0, 0, 0, 0.21)48.55%, rgba(0, 0, 0, 0.00)99.35%)',
        }}
      />
      <div className="pointer-events-none absolute left-0 top-0 flex size-full flex-col items-start justify-between p-6 pb-[27px] transition-colors duration-500 group-hover:bg-bright-green">
        {image.imageAlt && (
          <div className="line-clamp-6 max-w-[255px] font-larken text-2xl font-normal leading-6 text-off-white">
            {image.imageAlt}
          </div>
        )}
        {image.caption && (
          <div className="max-w-[408px] font-gotham text-sm font-s-light leading-6 text-white/75 opacity-0 transition-opacity duration-500 group-hover:opacity-100">
            {image.caption}
          </div>
        )}
      </div>
    </Box>
  );
};

export default MediaMasonryCard;
