import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, Button, FullBleedModal, Media } from '..';
import classNames from 'classnames';
import tailwindConfig from '@/tailwind.config.js';
import { fixColor } from '@/utils';

const ArticleCard = ({ data, className, backgroundColour, ...props }) => {
  const videoModal = useRef();

  const backgroundColor = fixColor(backgroundColour) || tailwindConfig.theme.colors.white;
  const dark = backgroundColor.toLowerCase() === tailwindConfig.theme.colors.white.toLowerCase();
  return (
    <div
      className={classNames(
        'flex h-full flex-col gap-s bg-off-white transition-colors duration-500 lg:gap-m',
        dark ? 'hover:bg-tan' : 'hover:bg-white',
        className,
      )}
      {...props}
    >
      <div className="px-xxs pt-xxs">
        <div className="image-wrapper relative aspect-[245/158] lg:aspect-[332/208]">
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 339, md: 299, lg: 480 }}
            heights={{ xs: 219, md: 193, lg: 301 }}
          />
          {data.preHeading && (
            <div className="absolute left-xs top-xs rounded-full bg-black/25 px-xs py-3 text-btn font-n-light not-italic leading-tiny-paragraph text-white backdrop-blur-[22px]">
              {data.preHeading}
            </div>
          )}
        </div>
      </div>
      <div className="mx-4 mb-xs flex h-full flex-col justify-between gap-xs">
        <div>
          {data.date && (
            <p className="mb-3 text-small-paragraph font-n-light not-italic leading-desktop-small text-black/50">
              {data.date}
            </p>
          )}
          {data.headingTitle && (
            <h1 className="mb-s line-clamp-1 font-larken text-xl font-normal not-italic leading-desktop-small lg:text-desktop-small">
              {data.headingTitle}
            </h1>
          )}

          {data.description && (
            <div
              className="line-clamp-2 text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </div>
        {data.primaryCta && (
          <div className="flex justify-end">
            {data.video &&
            (data.video.mobileVimeoId || data.video.vimeoId || data.video.videoFromGallery || data.video.youtubeId) ? (
              <Button className="btn general dark" onClick={() => videoModal.current.open()} role="button">
                {data.primaryCta.text}
              </Button>
            ) : (
              <Button className="btn general dark" link={data.primaryCta} role="button" />
            )}
          </div>
        )}
      </div>

      <FullBleedModal ref={videoModal}>
        <div className="size-full p-6">
          <Media
            media={{ ...data.video, autoPlay: true, coverImage: null }}
            className="flex items-center"
            controls
            background={false}
          />
        </div>
      </FullBleedModal>
    </div>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleCard;
