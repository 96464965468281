import {
  CarouselPagination,
  ModuleBase,
  ResponsiveImage,
  HeadingTag,
  Text,
  Stack,
  MediaLightboxCarousel,
} from '@/components';
import { isColorDark } from '@/utils';
import { AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { animControllerInView } from '@/animations';
import ExpandIcon from '@/assets/expand.svg';

export const fadeIn = {
  variants: {
    inactive: {
      opacity: 0,

      transition: {
        damping: 25,
        duration: 1,
      },
    },
    active: {
      opacity: 1,
      transition: {
        damping: 25,
        duration: 1,
      },
    },
  },
};

const HeroCarouselCentreAlignedModule = ({ data }) => {
  const carousel = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    carousel.current.on('ready', (e) => {
      if (data?.autoPlay) {
        e.currentTarget.addPlugins(new AutoPlay({ duration: 2000, direction: 'NEXT', stopOnHover: false }));
      }
    });
  }, [data?.autoPlay]);

  const isDescription = !!data?.mediaSlides?.find((slide) => slide.description);

  const imageList = data.mediaSlides.map((slide) => slide.image);

  return (
    <>
      <MediaLightboxCarousel
        open={activeImage !== null}
        activeImage={activeImage}
        onClose={() => setActiveImage(null)}
        slides={imageList}
        preloadSlides={imageList}
      />

      <ModuleBase data={data}>
        <div className="container px-2 md:px-3 lg:px-6">
          <Flicking ref={carousel} align="center" useResizeObserver onWillChange={(e) => setActiveSlide(e.index)}>
            {data.mediaSlides.map((slide, index) => {
              const isFocalPoint = slide.image.imageUrl.includes('rxy=');
              return (
                <Stack
                  motion={animControllerInView({ once: false, amount: 0.3 })}
                  key={index}
                  className="group relative h-[216px] w-full cursor-grab md:h-[464px] lg:h-[728px]"
                >
                  <ResponsiveImage
                    motion={fadeIn}
                    image={slide.image}
                    widths={{ xs: 409, sm: 640, md: 810, lg: 1392, xl: 1392, xxl: 1552 }}
                    heights={isFocalPoint ? { xs: 216, sm: 464, md: 728, lg: 728, xl: 728, xxl: 728 } : null}
                    className="pointer-events-none size-full select-none object-cover"
                  />
                  <div className="absolute right-6 top-4 transition-opacity duration-200 group-hover:opacity-100 lg:opacity-0">
                    <button
                      className="btn secondary bg-white/10 backdrop-blur-[6px]"
                      aria-label="Expand Image"
                      onClick={() => setActiveImage(index)}
                    >
                      <ExpandIcon />
                    </button>
                  </div>
                </Stack>
              );
            })}
          </Flicking>

          <div className="mt-2 flex flex-col px-4 md:px-3 lg:mt-3.5 lg:flex-row lg:justify-between lg:px-4">
            <div className="lg:w-1/3">
              <CarouselPagination
                carousel={carousel}
                className="mx-auto h-12 w-[148px] lg:m-0 [&_button]:!hidden"
                dark={!isColorDark(data.backgroundColour)}
              />
            </div>

            <div
              className={classNames(
                'mt-6 flex flex-col overflow-hidden lg:order-first lg:mt-[22px] lg:w-[424px]',
                isDescription ? 'lg:min-h-[120px]' : 'lg:min-h-[24px]',
              )}
            >
              <Stack key={activeSlide}>
                <HeadingTag
                  animate
                  data={{ htag: '', heading: data.mediaSlides[activeSlide].headingTitle }}
                  className="color-from-bg mb-4 text-base font-n-light tracking-paragraph"
                />
                <Text
                  dangerouslySetInnerHTML={{ __html: data.mediaSlides[activeSlide].description }}
                  className={classNames(
                    'text-sm font-s-light leading-6',
                    isColorDark(data.backgroundColour) ? 'text-white/75' : 'text-black/90',
                  )}
                />
              </Stack>
            </div>

            <CarouselPagination
              carousel={carousel}
              className="hidden !items-start !gap-6 lg:flex [&_.progress-container]:!hidden"
              dark={!isColorDark(data.backgroundColour)}
            />
          </div>
        </div>
      </ModuleBase>
    </>
  );
};
export default HeroCarouselCentreAlignedModule;
