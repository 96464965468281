import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Text from '../animation/Text';
import classNames from 'classnames';

const HeadingTag = forwardRef(({ data, animate = false, motion, children, className, ...props }, ref) => {
  const { htag, heading } = data || {};
  let polishedHeading = heading?.replace(/<(p|h[0-6])[^>]*>/g, '').replace(/<\/(p|h[0-6])>/g, '');
  const content = heading ? { dangerouslySetInnerHTML: { __html: polishedHeading } } : children;
  const headingTag = htag === 'p' ? 'h1' : htag;
  let accessibilityLevel = '7';
  if (htag && parseInt(htag[1]) > 0) {
    accessibilityLevel = htag[1];
  }

  return (
    <Text
      animate={animate || !!motion}
      motion={motion}
      tag={headingTag?.toLowerCase() || 'h1'}
      role="heading"
      aria-level={accessibilityLevel}
      className={classNames('whitespace-pre-wrap', className)}
      {...props}
      {...content}
      ref={ref}
    />
  );
});

HeadingTag.displayName = 'HeadingTag';

HeadingTag.propTypes = {
  data: PropTypes.exact({
    htag: PropTypes.string,
    heading: PropTypes.string,
  }).isRequired,
  animate: PropTypes.bool,
  children: PropTypes.node,
};

export default HeadingTag;
