import { ModuleBase, Button, HeadingTag, Stack, Text } from '@/components';
import tailwindConfig from '@/tailwind.config.js';
import '@egjs/react-flicking/dist/flicking.css';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';
import CarouselGeneric from './CarouselGeneric';
import CarouselFullBleed from './CarouselFullBleed';
import CarouselItinerary from './CarouselItinerary';

const CardCarouselGenericModule = ({ data }) => {
  const { cardRow, preHeading, headingTitle, description } = data || {};

  const backgroundColour = fixColor(data?.backgroundColour) || tailwindConfig.theme.colors.white;
  const isLight = !isColorDark(backgroundColour);

  return (
    <ModuleBase data={data} className="overflow-hidden">
      <div className="flex flex-col items-center gap-6 lg:gap-10">
        <Stack className="flex flex-col items-center gap-6 lg:gap-10">
          <div className="w-[345px] sm:w-[494px] lg:w-[432px]">
            {preHeading && (
              <Text
                tag="p"
                className={classNames(
                  'mb-3 text-center text-small-paragraph font-n-light not-italic leading-desktop-small sm:leading-4 lg:leading-desktop-small',
                  isLight ? 'text-black/75' : 'text-white/75',
                )}
              >
                {preHeading}
              </Text>
            )}
            {headingTitle && headingTitle.heading && (
              <HeadingTag
                animate
                data={headingTitle}
                className={classNames(
                  'text-center font-larken text-desktop-small font-light not-italic leading-mobile-medium md:text-desktop-medium md:leading-desktop-medium',
                  isLight ? 'text-black' : 'text-white',
                )}
                style={{ backgroundColour: backgroundColour }}
              />
            )}
          </div>
          {description && (
            <Text
              dangerouslySetInnerHTML={{ __html: description }}
              className={classNames(
                'w-[345px] text-center text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75 sm:w-[494px] lg:w-[432px]',
                isLight ? 'text-black/75' : 'text-white/75',
              )}
            />
          )}
        </Stack>
        {cardRow &&
          (['FullBleedLodgeCardRow', 'CustomFullBleedCardRowModel'].includes(cardRow[0]?.moduleName) ? (
            <CarouselFullBleed cardRow={cardRow} backgroundColour={backgroundColour} />
          ) : ['GalleryCardRow'].includes(cardRow[0]?.moduleName) ? (
            <CarouselItinerary cardRow={cardRow} backgroundColour={backgroundColour} />
          ) : (
            <CarouselGeneric cardRow={cardRow} backgroundColour={backgroundColour} />
          ))}

        {data?.primaryCta && (
          <Stack className="container flex w-full justify-center gap-4 [&_.box]:w-full sm:[&_.box]:w-auto">
            <Button
              animate
              className={classNames('btn secondary w-full sm:w-auto', isLight && 'dark')}
              link={data?.primaryCta}
            />
          </Stack>
        )}
      </div>
    </ModuleBase>
  );
};
export default CardCarouselGenericModule;
