import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import {
  useFloating,
  autoUpdate,
  offset as _offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useFocus,
  useInteractions,
  FloatingFocusManager,
} from '@floating-ui/react';

// https://ariakit.org/examples/menu-framer-motion

const PopoverMenu = forwardRef(
  (
    { title, open, onOpen, onClose, children, className, titleClass, offset = 0, placement, contentStyle, ...props },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState();
    const [show, setShow] = useState();

    const onOpenChange = (_open) => {
      if (_open) {
        onOpen && onOpen();
        setShow(_open);
      } else {
        onClose && onClose();
        setTimeout(() => {
          setShow(_open);
        }, 500);
      }
      setIsOpen(_open);
    };

    const {
      refs,
      floatingStyles,
      context,
      placement: computedPlacement,
    } = useFloating({
      open: isOpen,
      onOpenChange: onOpenChange,
      placement: placement,
      middleware: [_offset(offset), flip(), shift()],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([click, focus, dismiss, role]);

    useEffect(() => {
      onOpenChange(open);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useImperativeHandle(ref, () => {
      return {
        open() {
          onOpenChange(true);
        },
      };
    });

    const translate = {
      top: { translateY: 5 },
      bottom: { translateY: -5 },
    }[computedPlacement.includes('-') ? computedPlacement.split('-')[0] : computedPlacement];

    return (
      <div className={classNames('relative', isOpen && 'open', className, computedPlacement)} {...props}>
        <button
          type="button"
          className="main-button flex w-full items-center justify-between"
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <span
            className={classNames('popover-title', titleClass)}
            dangerouslySetInnerHTML={{
              __html: title?.replace ? title.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') : title,
            }}
          />
          <span className="arrow ml-4">
            <DropdownArrow
              role="presentation"
              className={classNames(
                'transition',
                isOpen
                  ? computedPlacement === 'top'
                    ? 'rotate-0'
                    : '-rotate-180'
                  : computedPlacement === 'top'
                    ? 'rotate-0'
                    : 'rotate-0',
              )}
            />
          </span>
        </button>
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="content-wrapper z-10 w-full focus:outline-none"
          >
            {show && (
              <motion.div
                className={classNames(
                  'content left-0 w-full origin-top transform border border-grey5 bg-white focus:outline-none',
                )}
                tabIndex="-1"
                variants={{
                  closed: {
                    opacity: 0,
                    ...translate,
                  },
                  open: {
                    opacity: 1,
                    translateX: 0,
                    translateY: 0,
                    transition: {
                      duration: 0.4,
                    },
                  },
                }}
                initial="closed"
                exit="closed"
                animate={isOpen ? 'open' : 'closed'}
                style={contentStyle}
              >
                {children}
              </motion.div>
            )}
          </div>
        </FloatingFocusManager>
      </div>
    );
  },
);

PopoverMenu.displayName = 'Box';

PopoverMenu.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
};

export default PopoverMenu;
