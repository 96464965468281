import React, { useEffect, useState } from 'react';
import { FlickingError, EVENTS } from '@egjs/react-flicking';
import { useTranslation } from 'next-i18next';
import CarouselArrow from '@/assets/carouselArrow.svg';
import classNames from 'classnames';
import { Box } from '..';

const CarouselPagination = ({ carousel, dark, hideIfIrrelevant, className, ...props }) => {
  const [progress, setProgress] = useState(0);
  const [disabledNext, setDisableNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    const _carousel = carousel.current;

    const calcProgress = () => {
      const currentAnchor = _carousel.camera.anchorPoints.find(
        (anchorPoint) => anchorPoint.position === _carousel.camera.position,
      );

      let currentAnchorIndex = currentAnchor?.index;
      let progress = Math.round(((currentAnchor?.index + 1) * 100) / _carousel.camera.anchorPoints.length);

      if (_carousel.camera.visiblePanels.length === _carousel.camera.children.length) {
        let minVisibleRatio = 1;
        _carousel.camera.visiblePanels.forEach((panel) => {
          minVisibleRatio = Math.min(minVisibleRatio, panel.visibleRatio);
        });

        // add tolerance Mac bugfix
        if (minVisibleRatio > 0.95) {
          progress = 100;
          currentAnchorIndex = 0;
        }
      }

      if (_carousel.circular) {
        setDisabledPrevious(false);
        setDisableNext(false);
      } else {
        if (progress === 100 && currentAnchorIndex === 0) {
          setDisableNext(true);
          setDisabledPrevious(true);
        } else if (progress === 100) {
          setDisableNext(true);
          setDisabledPrevious(false);
        } else if (currentAnchorIndex === 0) {
          setDisableNext(false);
          setDisabledPrevious(true);
        } else {
          setDisableNext(false);
          setDisabledPrevious(false);
        }
      }
      setProgress(progress);
    };

    const onResize = () => {
      setTimeout(() => {
        calcProgress();
      }, 500);
    };

    if (_carousel) {
      _carousel.on(EVENTS.READY, () => {
        setTimeout(() => {
          calcProgress();
        }, 500);
      });

      _carousel.on(EVENTS.CHANGED, calcProgress);

      /*
      _carousel.on(EVENTS.WILL_CHANGE, () => {
        if (!_carousel.circular) {
          setDisableNext(false);
          setDisabledPrevious(false);
        }
      });

      _carousel.on(EVENTS.REACH_EDGE, ({ direction }) => {
        if (!_carousel.circular) {
          if (direction === DIRECTION.NEXT) {
            setDisableNext(true);
            setDisabledPrevious(false);
          } else if (direction === DIRECTION.PREV) {
            setDisableNext(false);
            setDisabledPrevious(true);
          }
        }
      });
      */
      window.addEventListener('resize', onResize);
    }

    return () => {
      if (_carousel) {
        _carousel.off(EVENTS.READY);
        // _carousel.off(EVENTS.WILL_CHANGE);
        _carousel.off(EVENTS.CHANGED);
        // _carousel.off(EVENTS.REACH_EDGE);
        window.removeEventListener('resize', onResize);
      }
    };
  }, [carousel]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };
  return (
    <Box
      className={classNames(
        'flex items-center justify-between gap-9',
        disabledNext && disabledPrevious && hideIfIrrelevant && 'hidden',
        className,
      )}
      {...props}
    >
      <button
        className={classNames('btn icon previous', dark && 'dark')}
        onClick={move}
        aria-label={t('general.previous')}
        disabled={disabledPrevious}
      >
        <CarouselArrow className="rotate-0" />
      </button>

      <div className={classNames('progress-container h-1 w-full', dark ? 'bg-black/25' : 'bg-white/50')}>
        <div className="h-full bg-bright-orange transition-width" style={{ width: `${progress}%` }} />
      </div>

      <button
        className={classNames('btn icon next', dark && 'dark')}
        onClick={() => move(1)}
        aria-label={t('general.next')}
        disabled={disabledNext}
      >
        <CarouselArrow className="rotate-180" />
      </button>
    </Box>
  );
};
export default CarouselPagination;
