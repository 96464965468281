import { ModuleBase, Button, Text, HeadingTag, Box, Stack } from '@/components';
import DownloadIcon from '@/assets/download.svg';
import { animControllerInView } from '@/animations';

const DetailListModule = ({ data }) => {
  const { headingTitle, description, preHeading, primaryCta, details } = data || {};

  return (
    <ModuleBase data={data} className="bg-off-white">
      <Stack
        motion={animControllerInView({ amount: 0.3 })}
        className="container flex flex-col gap-16 px-6 py-6 sm:py-16 md:flex-row md:gap-6 md:px-10 lg:justify-between lg:gap-[148px] lg:px-[156px] lg:py-[104px]"
      >
        <Box className="md:w-1/2 md:pr-[49px] lg:w-[316px] lg:p-0">
          {preHeading && (
            <Text className="mb-3 font-gotham text-sm font-n-light leading-6 text-black/75">{preHeading}</Text>
          )}
          {headingTitle && (
            <HeadingTag
              animate
              data={headingTitle}
              className="mb-8 font-larken text-2xl font-light leading-7 text-black md:mb-12 md:text-desktop-medium md:leading-desktop-medium"
            />
          )}
          {description && (
            <div
              className="mb-8 font-gotham text-sm font-s-light leading-6 text-black/75 lg:mb-14"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {primaryCta && (
            <Button link={primaryCta} className="btn secondary dark w-full sm:w-auto">
              <DownloadIcon />
            </Button>
          )}
        </Box>
        {details.length > 0 && (
          <div className="flex w-full flex-col md:w-1/2 md:pt-[72px] lg:w-[664px] lg:pb-6 lg:pt-20">
            {details.map((element, index) => (
              <Box
                key={index}
                className="flex flex-col gap-4 border-b border-dashed border-black/25 py-4 font-gotham first:pt-0 last:border-none last:pb-0 md:py-6 lg:flex-row lg:gap-8 lg:px-4"
              >
                <Text className="text-base font-n-light tracking-paragraph lg:min-w-[200px]">
                  {element.statisticsLabel}
                </Text>
                <Text
                  className="richtext-content text-sm font-s-light leading-6 text-black/75"
                  dangerouslySetInnerHTML={{ __html: element.statistics }}
                />
              </Box>
            ))}
          </div>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default DetailListModule;
