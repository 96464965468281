import React, { useRef, useState, useEffect } from 'react';
import { ModuleBase, EntityCard, CarouselPagination, Button, HeadingTag, Stack, Text, Box } from '@/components';
import { listingGridItem } from '@/animations';
import Flicking from '@egjs/react-flicking';
import tailwindConfig from '@/tailwind.config.js';
import '@egjs/react-flicking/dist/flicking.css';
import { AnimatePresence } from 'framer-motion';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.5 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const SocialMediaCarousel = ({ data }) => {
  const { preHeading, headingTitle, description, images } = data || {};

  const [panelsPerView, setPanelsPerView] = useState(4);

  const carousel = useRef();

  const isLight = !isColorDark(data.backgroundColour);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setPanelsPerView(1.2);
      } else if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
        setPanelsPerView(2.4);
      } else {
        setPanelsPerView(4);
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <ModuleBase data={{ ...data, backgroundColour: '#DED9D3' }} className="overflow-hidden">
      <div className="flex flex-col items-center">
        <Stack className="mb-8 flex flex-col items-center lg:mb-10">
          <div className="mb-6 w-full px-s sm:w-[494px] lg:mb-8 lg:w-[432px]">
            {preHeading && (
              <Text
                tag="p"
                className={classNames(
                  'mb-3 text-center text-small-paragraph font-n-light not-italic leading-4',
                  isLight ? 'text-black/75' : 'text-white/75',
                )}
              >
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                animate
                data={headingTitle}
                className={classNames(
                  'text-center font-larken text-desktop-small font-light not-italic leading-mobile-medium sm:text-desktop-medium md:text-desktop-medium md:leading-desktop-medium',
                  isLight ? 'text-black' : 'text-white',
                )}
              />
            )}
          </div>
          {description && (
            <Text
              dangerouslySetInnerHTML={{ __html: description }}
              className={classNames(
                'w-full px-s text-center text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75 sm:w-[494px] lg:w-[432px]',
                isLight ? 'text-black/75' : 'text-white/75',
              )}
            />
          )}
        </Stack>
        <div className="container">
          <Stack motion={carouselMotion}>
            <AnimatePresence>
              <Flicking
                ref={carousel}
                panelsPerView={panelsPerView}
                align="center"
                horizontal
                bound
                preventDefaultOnDrag
                className="!overflow-visible"
                resizeOnContentsReady
              >
                {images.map((image, index) => (
                  <Box
                    key={index}
                    motion={listingGridItem}
                    className="mx-1.5 flex h-[316px] min-w-[316px] max-w-[316px] bg-white supports-[aspect-ratio]:aspect-square supports-[aspect-ratio]:h-[unset] supports-[aspect-ratio]:min-w-[unset] supports-[aspect-ratio]:max-w-[unset] sm:mx-3 lg:mx-4"
                  >
                    <EntityCard
                      data={{
                        moduleName: 'SocialCardModel',
                        props: {
                          image,
                        },
                      }}
                      className="w-full [&_.image-wrapper]:cursor-grab"
                      backgroundColour={data.backgroundColour}
                    />
                  </Box>
                ))}
              </Flicking>
            </AnimatePresence>
          </Stack>
          <Stack className="flex justify-center pt-8 lg:pt-10" role="slider" aria-orientation="horizontal">
            <CarouselPagination carousel={carousel} dark={isLight} className="w-[316px]" />
          </Stack>
        </div>

        <Stack className="mt-8 flex lg:mt-10">
          <Button animate className={classNames('btn secondary', isLight && 'dark')} link={data.primaryCta} />
        </Stack>
      </div>
    </ModuleBase>
  );
};
export default SocialMediaCarousel;
