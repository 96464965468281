import React, { useState, useRef } from 'react';
import { ModuleBase, UmbracoForm } from '@/components';
import { useTranslation } from 'next-i18next';
import { scrollIntoView } from '@/utils';

const FormModule = ({ data }) => {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation('common');
  const sucessMessage = useRef();

  const formDefinition = data.formFields;

  const onSubmitSuccessful = () => {
    setSubmitted(true);

    setTimeout(() => {
      scrollIntoView(sucessMessage.current);
    }, 500);
  };

  return (
    <ModuleBase data={{ ...data, backgroundColour: '#fff' }} className="text-black">
      <div ref={sucessMessage} className="container px-s sm:max-w-[482px] sm:px-xs lg:max-w-[896px]">
        <UmbracoForm
          className="[&_.buttons-container]:mt-m [&_.buttons-container]:sm:justify-center [&_.buttons-container]:lg:justify-start"
          successClass="text-center py-10 font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium"
          submitBtnClass="btn secondary dark w-full sm:w-auto"
          formDefinition={formDefinition}
          onSubmitSuccessful={onSubmitSuccessful}
        />
        {!submitted && (
          <div className="mt-m text-btn font-s-light not-italic leading-tiny-paragraph">
            {t('forms.$mandatoryfields')}
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default FormModule;
