import React, { forwardRef } from 'react';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import { convertAbsoluteLinkToRelative } from '@/utils';
import { Box } from '@/components';

const Link = forwardRef(({ animate, motion, link, children, ariaLabel, ...props }, ref) => {
  if (!link) return;

  const fixedLink = convertAbsoluteLinkToRelative(link);

  const linkObj = (
    <NextLink
      href={fixedLink?.href || ''}
      target={fixedLink?.target}
      aria-label={`${ariaLabel || link?.text}${fixedLink?.target === '_blank' ? ' (Opens new tab)' : ''}`}
      {...props}
      ref={ref}
    >
      {children || link?.text}
    </NextLink>
  );

  return !animate && !motion ? (
    linkObj
  ) : (
    <Box className="box" motion={motion}>
      {linkObj}
    </Box>
  );
});

Link.displayName = 'Link';

Link.propTypes = {
  link: PropTypes.object,
};

export default Link;
