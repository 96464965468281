import React from 'react';
import PopoverMenu from '../general/PopoverMenu';
import classNames from 'classnames';

const DropdownFilter = ({ filter, value, onChange, className, ...props }) => {
  const options = filter.filters?.map((option) => ({ label: option.name, value: option.filterGuid }));

  const _onChange = (e, _value) => {
    let newValue = value ? [...value] : [];

    if (e.target.checked && value.indexOf(_value) < 0) {
      newValue.push(_value);
    } else if (value.indexOf(_value) >= 0) {
      newValue.splice(value.indexOf(_value), 1);
    }

    const event = {};
    event[filter.filterFieldName] = newValue;

    onChange(event);
  };

  return (
    <PopoverMenu
      title={filter.filterLabel}
      className={classNames(
        value.length > 0 ? '[&_.popover-title]:font-n-light' : '[&_.popover-title]:font-s-light',
        '[&_.popover-title]:text-paragraph [&_.popover-title]:leading-desktop-small [&_.popover-title]:tracking-paragraph',
        '[&_.main-button]:px-4 [&_.main-button]:py-3',
        className,
      )}
      {...props}
    >
      <ul className="text-gray-700 dark:text-gray-200 w-full text-sm">
        {options &&
          options.map((option) => (
            <li key={option.value} className="border-b border-dashed border-black/25 last:border-b-0 hover:bg-grey6">
              <label className="checkbox flex justify-between px-xs py-xxs text-black">
                <div className="mr-2 block cursor-pointer text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph">
                  {option.label}
                </div>
                <input
                  type="checkbox"
                  className="w-[18px] accent-black"
                  value={option.id}
                  defaultChecked={value.includes(option.value)}
                  onChange={(e) => _onChange(e, option.value)}
                />
              </label>
            </li>
          ))}
      </ul>
    </PopoverMenu>
  );
};
export default DropdownFilter;
