import PropTypes from 'prop-types';
import { ResponsiveImage, Button } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import tailwindConfig from '@/tailwind.config.js';
import { fixColor } from '@/utils';

const PersonCard = ({ data, className, backgroundColour, ...props }) => {
  const { t } = useTranslation('common');

  const backgroundColor = fixColor(backgroundColour) || tailwindConfig.theme.colors.white;
  const dark = backgroundColor.toLowerCase() === tailwindConfig.theme.colors.white.toLowerCase();
  return (
    <div
      className={classNames(
        'flex h-full flex-col gap-s bg-off-white transition-colors duration-500 lg:gap-m',
        dark ? 'hover:bg-tan' : 'hover:bg-white',
        className,
      )}
      {...props}
    >
      <div className="p-xxs pb-0">
        <div className="image-wrapper relative aspect-[245/158] lg:aspect-[332/208]">
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 339, md: 299, lg: 480 }}
            heights={{ xs: 219, md: 193, lg: 301 }}
          />
          {data.preHeading && (
            <div className="absolute left-xs top-xs rounded-full bg-black/25 px-xs py-3 text-btn font-n-light not-italic leading-tiny-paragraph text-white backdrop-blur-[22px]">
              {data.preHeading}
            </div>
          )}
        </div>
      </div>
      <div className="mx-4 mb-xs flex h-full flex-col justify-between gap-xs">
        <div>
          {data.date && (
            <p className="mb-3 text-small-paragraph font-n-light not-italic leading-desktop-small text-black/50">
              {data.date}
            </p>
          )}
          {data.headingTitle && (
            <h1 className="mb-s line-clamp-2 font-larken text-xl font-normal not-italic leading-desktop-small lg:text-desktop-small">
              {data.headingTitle}
            </h1>
          )}

          {data.description && (
            <div
              className={classNames(
                'line-clamp-4 text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75 transition-all duration-300',
              )}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </div>
        {data.primaryCta && (
          <div className="flex justify-end">
            <Button
              className="btn general dark"
              link={{ ...data.primaryCta, text: t('general.$viewProfile') }}
              role="button"
            />
          </div>
        )}
      </div>
    </div>
  );
};

PersonCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PersonCard;
