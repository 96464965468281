import React, { useRef, useState, useEffect } from 'react';
import { ResponsiveImage, CarouselPagination, Stack, Box, Button, MediaLightboxCarousel } from '@/components';
import classNames from 'classnames';
import { listingGridItem } from '@/animations';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { AnimatePresence } from 'framer-motion';
import { isColorDark } from '@/utils';
import tailwindConfig from '@/tailwind.config.js';
import { useTranslation } from 'next-i18next';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.5 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const CarouselItinerary = ({ cardRow, backgroundColour }) => {
  const [panelsPerView, setPanelsPerView] = useState(1.2);
  const [panelsSize, setPanelsSize] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeGallery, setActiveGallery] = useState(null);
  const carousel = useRef();
  const { t } = useTranslation('common');

  const isLight = !isColorDark(backgroundColour);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setPanelsPerView(1.03);
      } else {
        setPanelsPerView(1.2);
      }
      calcPanelsSize();
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const calcPanelsSize = async () => {
    setTimeout(() => {
      setPanelsSize(carousel.current.camera?.visiblePanels[0]?.size);
    }, 500);
  };

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  return (
    <>
      <MediaLightboxCarousel
        open={activeGallery !== null}
        onClose={() => setActiveGallery(null)}
        slides={activeGallery?.gallery}
        preloadSlides={cardRow[0]?.props?.cards[currentIndex]?.props?.gallery}
      />
      {cardRow?.map((row, rIndex) => (
        <div className="container" key={rIndex}>
          <Stack motion={carouselMotion}>
            <Box className="mb-4 w-full text-small-paragraph font-n-light not-italic leading-desktop-small">
              <span className={classNames(isLight ? 'text-black' : 'text-white')}>
                {(currentIndex + 1 + '').padStart(2, '0')}
              </span>{' '}
              <span className={classNames(isLight ? 'text-black/25' : 'text-white/25')}>/</span>{' '}
              <span className={classNames(isLight ? 'text-black/25' : 'text-white/25')}>
                {(row.props.cards?.length + '').padStart(2, '0')}
              </span>
            </Box>
            <AnimatePresence>
              <Flicking
                ref={carousel}
                panelsPerView={panelsPerView}
                align="prev"
                preventDefaultOnDrag
                className="!overflow-visible"
                resizeOnContentsReady
                onWillChange={willChange}
                onReady={calcPanelsSize}
              >
                {row.props.cards.map((card, index) => (
                  <Box key={index} motion={listingGridItem} className="mx-1.5 flex flex-col sm:mx-2">
                    <div
                      className={classNames(
                        'overlay relative aspect-[345/194] w-full transition-opacity duration-500 after:opacity-0 after:transition-all after:duration-500 lg:aspect-[1128/634]',
                        index !== currentIndex ? 'opacity-50 after:opacity-100' : '',
                      )}
                    >
                      <ResponsiveImage
                        image={card.props.image}
                        widths={{ xs: 381, sm: 464, md: 571, lg: 784, xl: 997, xxl: 1264 }}
                        heights={{ xs: 214, sm: 261, md: 321, lg: 441, xl: 561, xxl: 710 }}
                        className="cursor-grab"
                      />
                      <div className="absolute right-4 top-4">
                        <Button
                          className="btn secondary bg-white/10 backdrop-blur-[6px]"
                          onClick={() => setActiveGallery(card.props)}
                        >
                          {t('general.$opengallery')}
                        </Button>
                      </div>
                    </div>

                    <div
                      className={classNames(
                        'h-fit w-full transition-opacity duration-500 lg:hidden',
                        index !== currentIndex ? 'opacity-0' : '',
                      )}
                    >
                      <p
                        className={classNames(
                          'mb-3 mt-m line-clamp-1 text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph sm:mt-3',
                          isLight ? 'text-black' : 'text-white',
                        )}
                      >
                        {card.props.headingTitle}
                      </p>

                      <div
                        className={classNames(
                          'line-clamp-6 text-small-paragraph font-s-light not-italic leading-desktop-small',
                          isLight ? 'text-black/75' : 'text-white/75',
                        )}
                        dangerouslySetInnerHTML={{ __html: card.props.description }}
                      />
                    </div>
                  </Box>
                ))}
              </Flicking>
            </AnimatePresence>
          </Stack>
          <Stack
            className="relative mt-m flex flex-col justify-between sm:mt-10 sm:flex-row lg:mt-8 lg:gap-m"
            style={{ width: panelsSize + 'px' }}
          >
            <Box className="relative hidden h-[120px] w-full lg:block lg:max-w-[664px]">
              {row.props.cards.map((card, index) => (
                <div
                  key={index}
                  className={classNames(
                    'absolute size-full transition-opacity duration-500',
                    currentIndex === index ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  <div className="h-fit w-full">
                    <p
                      className={classNames(
                        'mb-xs line-clamp-1 text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph',
                        isLight ? 'text-black' : 'text-white',
                      )}
                    >
                      {card.props.headingTitle}
                    </p>

                    <div
                      className={classNames(
                        'line-clamp-3 text-small-paragraph font-s-light not-italic leading-desktop-small',
                        isLight ? 'text-black/75' : 'text-white/75',
                      )}
                      dangerouslySetInnerHTML={{ __html: card.props.description }}
                    />
                  </div>
                </div>
              ))}
            </Box>
            <Box className="flex justify-center gap-s" aria-orientation="horizontal">
              <div className="w-[148px] lg:w-auto">
                <CarouselPagination
                  carousel={carousel}
                  hideIfIrrelevant
                  dark={isLight}
                  className="h-12 sm:h-auto lg:gap-s [&_.btn]:hidden [&_.btn]:lg:flex [&_.progress-container]:lg:hidden"
                />
              </div>
            </Box>
          </Stack>
        </div>
      ))}
    </>
  );
};
export default CarouselItinerary;
