import PropTypes from 'prop-types';
import { ResponsiveImage, Link } from '..';
import classNames from 'classnames';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { Pagination } from '@egjs/flicking-plugins';
import Location from '@/assets/location.svg';
import { useTranslation } from 'next-i18next';
import tailwindConfig from '@/tailwind.config.js';
import { fixColor } from '@/utils';

const LodgeCard = ({ data, className, backgroundColour, ...props }) => {
  const { t } = useTranslation('common');
  const backgroundColor = fixColor(backgroundColour) || tailwindConfig.theme.colors.white;
  const dark = backgroundColor.toLowerCase() === tailwindConfig.theme.colors.white.toLowerCase();

  const _plugins = data.images.length > 1 ? [new Pagination({ type: 'bullet' })] : [];

  const content = (
    <>
      <div className="flex flex-col gap-s">
        {data.headingTitle && (
          <h1 className="font-larken text-xl font-normal not-italic leading-desktop-small text-black sm:text-desktop-small">
            {data.headingTitle}
          </h1>
        )}

        {data.description && (
          <p
            className="text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        )}
      </div>
      {data.keyFeatures.length > 0 && (
        <div className="grid gap-2 border-dashed border-black/25 lg:mx-xxs lg:mt-s lg:grid-cols-2 lg:gap-0 lg:border-t">
          {data.keyFeatures.map((feature, index) => {
            return index < 4 ? (
              <div
                key={index}
                className={classNames('border-dashed border-black/25 lg:border-b lg:py-xs', index > 3 && 'hidden')}
              >
                <div className="font-gotham text-small-paragraph font-s-light not-italic leading-desktop-small text-black/75 before:mr-2.5 before:content-facility lg:pl-xxs">
                  {feature}
                </div>
              </div>
            ) : null;
          })}
        </div>
      )}
    </>
  );

  return (
    <div
      className={classNames(
        'flex h-full flex-col gap-m bg-off-white p-xxs pb-s transition-colors duration-500 lg:pb-10',
        dark ? 'hover:bg-tan' : 'hover:bg-white',
        className,
      )}
      {...props}
    >
      <div className="relative">
        <div className="image-wrapper relative aspect-[329/224] w-full sm:aspect-[353/224] lg:aspect-[647/362]">
          <Flicking
            align="center"
            bound={data.images.length <= 1}
            preventDefaultOnDrag
            className="h-full"
            circular={data.images.length > 1}
            plugins={_plugins}
            bounce={data.images.length === 1 ? 0 : '20%'}
          >
            {data.images.map((image, index) => (
              <Link key={index} className="w-full" link={data.primaryCta}>
                <ResponsiveImage
                  image={image}
                  widths={{ xs: 377, sm: 320, md: 448, lg: 576, xl: 656, xxl: 736 }}
                  heights={{ xs: 257, sm: 207, md: 284, lg: 322, xl: 367, xxl: 412 }}
                  className={classNames(data.images.length > 1 && 'cursor-grab')}
                />
              </Link>
            ))}
            {data.images.length > 1 && (
              <ViewportSlot>
                <div className="flicking-pagination !bottom-3 [&_.flicking-pagination-bullet.flicking-pagination-bullet-active]:bg-bright-orange [&_.flicking-pagination-bullet]:border [&_.flicking-pagination-bullet]:border-white/50 [&_.flicking-pagination-bullet]:bg-white/50"></div>
              </ViewportSlot>
            )}
          </Flicking>

          {data.label && data.locationPage && (
            <Link
              link={data.locationPage}
              className="absolute left-2 top-2 z-10 flex items-center gap-xs rounded-full bg-black/25 px-xs py-3 backdrop-blur-[22px] transition-all duration-700 hover:bg-bright-orange lg:left-4 lg:top-4"
            >
              <Location className="fill-white" />
              <p className="text-btn font-n-light not-italic leading-tiny-paragraph text-white">{data.label}</p>
            </Link>
          )}
        </div>
      </div>
      <div className="flex h-full flex-col justify-between gap-s px-xxs lg:px-s">
        <Link className="hidden flex-col justify-between gap-s lg:flex" link={data.primaryCta}>
          {content}
        </Link>
        <div className="flex flex-col justify-between gap-s lg:hidden">{content}</div>
        <Link link={{ ...data.primaryCta, text: t('general.$viewlodge') }} className="btn secondary dark lg:hidden" />
      </div>
    </div>
  );
};

LodgeCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LodgeCard;
