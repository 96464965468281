import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isValidHttpUrl } from '@/utils';

const YouTubePlayer = forwardRef(function VideoPlayer(
  // eslint-disable-next-line no-unused-vars
  { media, cover, onAutoPlayStarted, controls, className, ...props },
  ref,
) {
  let player = useRef();
  useImperativeHandle(ref, () => {
    return {
      openFullscreen() {},
      play() {},
      pause() {},
      unMute() {},
      mute() {},
    };
  }, []);

  if (!media) {
    return null;
  }

  const validHttpUrl = isValidHttpUrl(media.youtubeId);
  let youTubeUrl = media.youtubeId;
  if (validHttpUrl) {
    const _url = new URL(media.youtubeId);
    const videoId = _url.searchParams.get('v');
    if (videoId) {
      youTubeUrl = 'https://www.youtube.com/embed/' + videoId;
    }
  } else {
    youTubeUrl = 'https://www.youtube.com/embed/' + media.youtubeId;
  }

  if (isValidHttpUrl(youTubeUrl)) {
    const _url = new URL(youTubeUrl);
    _url.searchParams.set('rel', 0);
    _url.searchParams.set('modestbranding', 0);

    if (media.autoPlay) {
      _url.searchParams.set('autoplay', 1);
    }
    if (media.loop) {
      _url.searchParams.set('loop', 1);
    }

    if (controls) {
      _url.searchParams.set('controls', 1);
    }
    youTubeUrl = _url.href;
  }

  return (
    <iframe
      ref={player}
      className={classNames('youtube aspect-video w-full', className)}
      src={youTubeUrl}
      title="YouTube video player"
      allow="accelerometer; 
        autoplay; 
        clipboard-write; 
        encrypted-media; 
        gyroscope; 
        picture-in-picture; 
        web-share"
      allowFullScreen={media.allowFullScreen}
      {...props}
    />
  );
});

YouTubePlayer.propTypes = {
  media: PropTypes.object.isRequired,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
};

export default YouTubePlayer;
