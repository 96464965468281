import { useState } from 'react';
import { ModuleBase, Box, Stack } from '@/components';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import SeasonCard from './SeasonCard';
import { AnimatePresence } from 'framer-motion';
import { listingGridItem } from '@/animations';
import { isColorDark, fixColor } from '@/utils';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.5 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};
const SeasonsCarousel = ({ data }) => {
  const [currentSeason, setCurrentSeason] = useState(0);
  const isDark = isColorDark(fixColor(data?.backgroundColour));

  return (
    <ModuleBase data={data} className="overflow-hidden">
      <div className="flex flex-col gap-6 py-6 sm:gap-[60px] sm:py-4 lg:hidden">
        <div className="container">
          <div
            className={classNames(
              'relative flex h-12 rounded-sm p-4 backdrop-blur md:justify-center md:gap-14',
              isDark ? 'bg-white/[0.05]' : 'bg-black/[0.05]',
            )}
          >
            {data.seasons.map((season, index) => (
              <div
                key={`season-${index}`}
                className={classNames(
                  'line-clamp-1 flex w-full cursor-pointer flex-col items-center px-3 py-1 text-center font-gotham text-xs font-normal uppercase leading-tiny-paragraph transition-colors md:w-fit',
                  currentSeason === index
                    ? isDark
                      ? 'text-white'
                      : 'text-black'
                    : isDark
                      ? 'text-white/50'
                      : 'text-black/50',
                )}
                onClick={() => setCurrentSeason(index)}
              >
                {season.title}
                {currentSeason === index ? (
                  <motion.div className="absolute bottom-0 h-[3px] w-[91px] bg-bright-orange" layoutId="underline" />
                ) : null}
              </div>
            ))}
          </div>
        </div>
        <SeasonCard
          key={`card-${currentSeason}`}
          dark={isDark}
          season={data.seasons[currentSeason]}
          seasonsCount={data.seasons.length}
        />
      </div>

      <div className="container hidden py-14 lg:block">
        <Stack
          motion={carouselMotion}
          className={classNames('relative mt-12 w-full md:mt-[52px] lg:mt-0 lg:grid', {
            'lg:grid-cols-1': data.seasons.length === 1,
            'lg:grid-cols-2': data.seasons.length === 2,
            'lg:grid-cols-3': data.seasons.length === 3,
          })}
        >
          <AnimatePresence>
            {data.seasons &&
              data.seasons.map((season, index) => (
                <Box key={index} motion={listingGridItem}>
                  <SeasonCard dark={isDark} season={season} seasonsCount={data.seasons.length} />
                </Box>
              ))}
          </AnimatePresence>
        </Stack>
      </div>
    </ModuleBase>
  );
};

export default SeasonsCarousel;
