import React from 'react';
import { HeadingTag, Button, ModuleBase, ResponsiveImage, Stack, Text, Box, ImageCarousel } from '@/components';
import classNames from 'classnames';
import { isColorDark, fixColor } from '@/utils';

const TextAndTwoMediaModule = ({ data }) => {
  const darkBg = isColorDark(fixColor(data.backgroundColour));

  const heading = (
    <div className="flex flex-col gap-3 lg:items-center">
      {data.preHeading && (
        <Text
          className={classNames(
            'font-gotham text-sm font-n-light leading-6 lg:mb-3',
            darkBg ? 'text-white/75' : 'text-black/75',
          )}
        >
          {data.preHeading}
        </Text>
      )}
      {data.headingTitle && (
        <HeadingTag
          animate
          data={data.headingTitle}
          className={classNames(
            'font-larken text-desktop-small font-light not-italic leading-mobile-medium sm:text-desktop-medium sm:leading-10 lg:w-[328px] lg:text-[38px]',
            darkBg ? 'text-white' : 'text-black',
          )}
        />
      )}
    </div>
  );

  const descriptionAndCTA = (data.description || data.primaryCTA) && (
    <>
      {data.description && (
        <Box
          className={classNames(
            'font-gotham text-sm font-s-light leading-6 opacity-90 lg:w-2/3',
            darkBg ? 'text-white/75' : 'text-black/75',
          )}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
      {data.primaryCTA && (
        <Button
          animate
          link={data.primaryCTA}
          className={classNames('btn secondary w-full sm:w-auto sm:!px-11 lg:!px-5', !darkBg && 'dark')}
        />
      )}
    </>
  );

  const mobileImage = (
    <ImageCarousel
      loop
      images={[data.leftMedia, data.rightMedia]}
      portrait={false}
      imageWidths={{ xs: 365, sm: 332, md: 460 }}
      imageHeights={{ xs: 440, sm: 400, md: 555 }}
      wrapperClass="aspect-[345/416] sm:aspect-[365/440]"
      wrapperClass2="w-full"
      isDarkBg={darkBg}
    />
  );

  return (
    <ModuleBase data={{ ...data }}>
      <div className="container flex flex-col items-center gap-8 px-6 sm:px-24 md:gap-10 lg:gap-16 xl:px-[272px]">
        <Stack className="text-wrapper w-full sm:hidden lg:block lg:text-center">{heading}</Stack>

        <Stack className="hidden w-full flex-col gap-y-[17px] lg:flex">
          <div className="flex w-full gap-x-4 lg:gap-x-8">
            {data.leftMedia && (
              <div className="flex w-full flex-col">
                <div className="aspect-[432/552] w-full">
                  <ResponsiveImage
                    animate
                    image={data.leftMedia}
                    widths={{ xs: 432, sm: 432, md: 432, lg: 432, xl: 432 }}
                    heights={{ xs: 552, sm: 552, md: 552, lg: 552, xl: 552 }}
                  />
                </div>
                <Text
                  className={classNames(
                    'captions mt-2.5 line-clamp-2 font-gotham text-small-paragraph font-n-light not-italic leading-desktop-small',
                    darkBg ? 'text-white' : 'text-black',
                  )}
                >
                  {data.leftMedia.caption}
                </Text>
              </div>
            )}
            {data.rightMedia && (
              <div className="flex w-full flex-col">
                <div className="aspect-[432/552] w-full">
                  <ResponsiveImage
                    animate
                    image={data.rightMedia}
                    widths={{ xs: 432, sm: 432, md: 432, lg: 432, xl: 432 }}
                    heights={{ xs: 552, sm: 552, md: 552, lg: 552, xl: 552 }}
                  />
                </div>
                <Text
                  className={classNames(
                    'captions mt-2.5 line-clamp-2 text-right font-gotham text-small-paragraph font-n-light not-italic leading-desktop-small',
                    darkBg ? 'text-white' : 'text-black',
                  )}
                >
                  {data.rightMedia.caption}
                </Text>
              </div>
            )}
          </div>
        </Stack>

        <Stack className="flex sm:hidden">{mobileImage}</Stack>

        <Stack className="flex w-full flex-col gap-8 sm:hidden sm:flex-row sm:justify-between sm:gap-0 lg:flex">
          {descriptionAndCTA}
        </Stack>
      </div>
      <Stack className="container hidden gap-6 sm:flex lg:hidden">
        <Box className="w-1/2">{mobileImage}</Box>
        <Box className="flex w-1/2 flex-col justify-center gap-6 pr-6">
          {heading}
          {descriptionAndCTA}
        </Box>
      </Stack>
    </ModuleBase>
  );
};

export default TextAndTwoMediaModule;
