import classNames from 'classnames';
import NavItem from './NavItem';
import { Button } from '@/components';
import { useTranslation } from 'next-i18next';
import NavArrow from '@/assets/navArrow.svg';

const NavColumn = ({
  navItems,
  level,
  activeMenu,
  setActiveMenu,
  close,
  cta,
  title,
  backButton,
  setBackgroundImage,
  parentBackgroundImage,
}) => {
  const lKey = `l${level}`;
  const _setActiveMenu = (index, subMenu) => {
    let newValue = {};
    newValue[`m${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const { t } = useTranslation('common');

  return (
    <div
      className={classNames(
        'pointer-events-none absolute left-0 top-0 h-full w-full origin-right overflow-hidden transition-all duration-500 ease-in-out',
        !(activeMenu && activeMenu[lKey])
          ? 'translate-x-full opacity-0 lg:w-0 lg:translate-x-0 lg:opacity-100 lg:ease-out'
          : 'opacity-100 lg:!z-10 lg:duration-1000 lg:ease-in',
        {
          'z-0': level === 0,
          'z-10 lg:left-[295px] lg:-z-10': level === 1,
          'z-20 lg:left-[348px] lg:-z-20': level === 2,
          'z-30 lg:left-[348px] lg:-z-30': level === 3,
        },
      )}
    >
      <div className="pointer-events-none relative h-full w-full">
        <div
          className={classNames(
            'pointer-events-auto flex h-full flex-col',
            level === 0 && 'bg-black/80 lg:w-[295px] lg:bg-black/75',
            {
              'bg-black lg:w-[348px] lg:bg-black/80': level === 1,
              'bg-black lg:w-[348px] lg:bg-black/90': level === 2 || level === 3,
            },
          )}
        >
          {backButton && (
            <button className="flex items-center px-6 pt-4 sm:px-10 lg:hidden" onClick={() => setActiveMenu(null)}>
              <NavArrow className="m-3 ml-0 stroke-white" role="presentation" />
              <span className="text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph text-white">
                {t('general.$back')}
              </span>
            </button>
          )}
          {title && (
            <div
              className={classNames(
                'px-6 font-larken text-desktop-small font-light not-italic leading-mobile-medium text-white sm:px-10 lg:hidden',
                backButton ? 'pb-5 pt-[29px] sm:pb-[15px] sm:pt-12' : 'pb-[43px] pt-14 sm:pb-[15px] sm:pt-[88px]',
              )}
            >
              {title}
            </div>
          )}

          <div
            data-lenis-prevent
            className={classNames(
              'flex h-full max-h-full flex-col gap-8 overflow-y-auto p-6 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/25 sm:p-10 lg:gap-0 lg:pb-12 lg:pl-10 lg:pr-10 lg:pt-20',
              title ? 'pt-5' : 'justify-center lg:justify-start',
              level !== 0 && 'pb-20 lg:pb-0',
            )}
          >
            <div className={classNames('flex flex-col lg:gap-8', !title && 'gap-m')}>
              {navItems?.map((nav, index) => (
                <NavItem
                  navItem={nav}
                  key={index}
                  level={level}
                  activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`m${index}`] : {}) : {}}
                  setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
                  close={close}
                  title={title}
                  setBackgroundImage={setBackgroundImage}
                  parentBackgroundImage={parentBackgroundImage}
                />
              ))}
            </div>
          </div>
          {cta && (
            <div className="flex w-full justify-center bg-[#1A1A1A] px-s pb-m pt-s lg:justify-start lg:bg-transparent lg:px-10 lg:pb-12 lg:pt-lg">
              <Button
                className={classNames('btn w-full sm:w-auto', {
                  secondary: level === 0,
                  general: level === 1 || level === 2,
                })}
                link={cta}
                onClick={close}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavColumn;
