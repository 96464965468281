import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Filters from '@/assets/filters.svg';
import { Box, ModalPortal, Stack } from '..';
import Close from '@/assets/close.svg';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import { accordionPanel } from '@/animations';
import { motion } from 'framer-motion';
import { modalWrapper, modalBackdrop, modalController } from '@/animations';
import { AnimatePresence } from 'framer-motion';

const DropdownFilter = ({ filterValues, filters, onChange, className, dark, clearAll, ...props }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [animateDelay, setAnimateDelay] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [_filterValues, set_FilterValues] = useState(null);

  const filterGroups = filters.map((filter) => ({
    filterLabel: filter.filterLabel,
    filterFieldName: filter.filterFieldName,
    options: filter.filters?.map((option) => ({ label: option.name, value: option.filterGuid })),
  }));

  useEffect(() => {
    set_FilterValues(filterValues);
  }, [filterValues]);

  const _onChange = (e, _filterFieldName, _value) => {
    const value = _filterValues[_filterFieldName];

    let newValue = value ? [...value] : [];

    if (e.target.checked && value.indexOf(_value) < 0) {
      newValue.push(_value);
    } else if (value.indexOf(_value) >= 0) {
      newValue.splice(value.indexOf(_value), 1);
    }

    const newFilterValues = _filterValues;
    newFilterValues[_filterFieldName] = newValue;
    set_FilterValues(newFilterValues);
  };

  const _open = () => {
    setAnimateDelay(true);
    let firstActiveFilter = null;
    Object.entries(filterValues).forEach((entry, index) => {
      if (firstActiveFilter === null && entry[1] && entry[1].length > 0) {
        firstActiveFilter = index;
      }
    });
    setActiveFilter(firstActiveFilter);
    setOpen(true);
  };

  const close = () => {
    setAnimateDelay(false);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const applyFilters = () => {
    onChange(_filterValues);
    close();
  };

  const _clearAll = () => {
    clearAll();
    close();
  };

  return (
    <>
      <button
        type="button"
        className={classNames('main-button flex w-full items-center justify-center gap-xxs px-4 py-3', className)}
        onClick={_open}
        {...props}
      >
        <Filters className={classNames(dark ? 'fill-white/75' : 'fill-black')} />
        <span
          className={classNames(
            'text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph',
            dark ? 'text-white/75' : 'fill-black/75',
          )}
        >
          {t('general.$filters')}
        </span>
      </button>
      {open && (
        <ModalPortal>
          <Stack motion={modalWrapper} className="disable-scroll fixed top-0 z-50 h-screen w-screen">
            <AnimatePresence>
              {animateDelay && (
                <div className="relative h-full w-full">
                  <Box
                    motion={modalBackdrop}
                    className="absolute left-0 top-0 h-screen w-screen bg-black/90"
                    onClick={close}
                  />
                  <Box motion={modalController} className="h-full w-full px-xxs py-xs">
                    <div className="relative flex h-full w-full flex-col justify-between bg-white py-s">
                      <div className="flex h-full flex-col">
                        <div className="mb-s flex items-center justify-between px-s">
                          <div className="font-larken text-xl font-normal not-italic leading-desktop-small">
                            {t('general.$filters')}
                          </div>
                          <button className="p-3" onClick={close}>
                            <Close className="fill-black" />
                          </button>
                        </div>
                        <div
                          data-lenis-prevent
                          className="h-full max-h-full overflow-y-auto px-s scrollbar-thin scrollbar-track-transparent scrollbar-thumb-black/25"
                        >
                          {filterGroups.map((group, index) => {
                            const activeFiltersCount =
                              _filterValues && _filterValues[group.filterFieldName]
                                ? _filterValues[group.filterFieldName].length
                                : 0;

                            return (
                              <div className="" key={index}>
                                <button
                                  className="flex h-12 w-full cursor-pointer items-center justify-between gap-xxs border-b border-dashed border-black/25 bg-white"
                                  onClick={() => setActiveFilter(activeFilter === index ? null : index)}
                                >
                                  <span className="text-paragraph font-n-light not-italic leading-desktop-small tracking-paragraph">
                                    {group.filterLabel}{' '}
                                    {activeFiltersCount > 0 && (
                                      <span className="font-light" key={index}>
                                        ({_filterValues[group.filterFieldName].length})
                                      </span>
                                    )}
                                  </span>
                                  <DropdownArrow
                                    className={classNames(
                                      'm-3 stroke-black transition-all duration-500',
                                      activeFilter === index && 'rotate-180',
                                    )}
                                  />
                                </button>
                                <motion.div
                                  variants={accordionPanel.variants}
                                  initial="inactive"
                                  animate={activeFilter === index ? 'active' : 'inactive'}
                                  className={classNames('w-full', activeFilter !== index && 'overflow-hidden')}
                                >
                                  <ul className="flex flex-col gap-xs py-xs text-sm">
                                    {group.options &&
                                      group.options.map((option) => (
                                        <li key={option.value} className="">
                                          <label className="checkbox flex text-black">
                                            <input
                                              type="checkbox"
                                              className="w-[18px] accent-black"
                                              value={option.value}
                                              defaultChecked={
                                                _filterValues &&
                                                _filterValues[group.filterFieldName].includes(option.value)
                                              }
                                              onChange={(e) => _onChange(e, group.filterFieldName, option.value)}
                                            />

                                            <div className="ml-xxs block cursor-pointer text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph">
                                              {option.label}
                                            </div>
                                          </label>
                                        </li>
                                      ))}
                                  </ul>
                                </motion.div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="flex flex-col gap-xs border-t border-solid border-black/25 px-s pt-s">
                          <button className="btn secondary dark w-full" onClick={_clearAll}>
                            {t('general.$clearall')}
                          </button>
                          <button className="btn primary w-full" onClick={applyFilters}>
                            {t('general.$apply')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              )}
            </AnimatePresence>
          </Stack>
        </ModalPortal>
      )}
    </>
  );
};

export default DropdownFilter;
