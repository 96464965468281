import { Link } from '@/components';
import classNames from 'classnames';

const LodgeNav = ({ navItems, className }) => {
  if (!navItems) return null;

  const navCass =
    'nav-link  py-xs text-center text-btn font-n-light uppercase not-italic leading-3 tracking-heading5 transition-colors duration-500  sm:px-3';

  return (
    <div className={classNames('relative', className)}>
      <div className="container flex h-12 w-full items-center justify-around gap-s overflow-auto whitespace-nowrap scrollbar-hide sm:justify-center">
        {navItems.map((nav, index) => (
          <Link className={classNames('hidden sm:block', navCass)} link={nav} key={index} />
        ))}
        {navItems.map((nav, index) => (
          <Link
            className={classNames('sm:hidden', navCass)}
            link={{ ...nav, text: nav?.text?.split(' ').pop() }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default LodgeNav;
