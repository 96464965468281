import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Text, Box, Media } from '..';
import classNames from 'classnames';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import { motion } from 'framer-motion';

const transition = {
  type: 'spring',
  damping: 20,
  stiffness: 80,
};

const slowTransition = {
  type: 'spring',
  delay: 0.3,
  damping: 30,
  stiffness: 80,
};

const fadeUp = (marginTop) => {
  return {
    cardInactive: {
      opacity: 0,
      height: 0,
      marginTop: 0,
      y: 20,
    },
    cardActive: {
      opacity: 1,
      height: 'auto',
      marginTop: marginTop,
      y: 0,
      transition: {
        height: {
          ...transition,
        },
        opacity: {
          ...slowTransition,
        },
        y: {
          ...slowTransition,
        },
      },
    },
  };
};

const childAnims = {
  container: {
    variants: {
      cardInactive: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        transition,
      },
      cardActive: {
        backgroundColor: 'rgba(0,0,0,0.75)',
        transition,
      },
    },
  },
  arrow: {
    variants: {
      cardInactive: {
        rotate: 180,
        transition,
      },
      cardActive: {
        rotate: 0,
        transition,
      },
    },
  },
  description: {
    variants: fadeUp('16px'),
  },
  buttonGroup: {
    variants: fadeUp('16px'),
  },
};

// eslint-disable-next-line no-unused-vars
const ExperienceCard = ({ data, className, backgroundColour, ...props }) => {
  const [isHover, setHover] = useState(false);
  const video = useRef();

  useEffect(() => {
    if (isHover) {
      video.current.play();
    } else {
      video.current.pause();
    }
  }, [isHover]);

  return (
    <motion.div
      initial="cardInactive"
      animate={isHover ? 'cardActive' : 'cardInactive'}
      className={classNames(
        'relative flex aspect-[345/510] h-[510px] w-full flex-col justify-end supports-[aspect-ratio]:h-[min-content] lg:aspect-[432/640] lg:h-[640px]',
        className,
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <div className="image-wrapper absolute h-full w-full overflow-hidden">
        <Media
          ref={video}
          media={data.video && data.video?.vimeoId?.length > 0 ? data.video : { coverImage: data.image }}
          widths={{ xs: 355, sm: 254, md: 315, lg: 384, xl: 384, xxl: 491 }}
          heights={{ xs: 525, sm: 376, md: 466, lg: 568, xl: 569, xxl: 727 }}
          cover
          muted
          controls={false}
          background={false}
          hideVideoUntilPlay
          className="pointer-events-none"
        />
      </div>
      <Box
        motion={childAnims.container}
        className="relative m-xxs flex flex-col overflow-hidden p-xs backdrop-blur-[22px]"
      >
        <div
          className="flex items-center justify-between"
          onPointerDownCapture={(e) => {
            e.stopPropagation();
            setHover(!isHover);
          }}
        >
          <h1
            role="heading"
            aria-level="3"
            className="line-clamp-2 font-larken text-xl font-normal not-italic leading-desktop-small text-white md:text-desktop-small"
          >
            {data.headingTitle}
          </h1>
          <Box motion={childAnims.arrow}>
            <DropdownArrow className="stroke-white transition-transform duration-700" role="presentation" />
          </Box>
        </div>

        {data.description && (
          <Text
            tag="div"
            motion={childAnims.description}
            className="text-small-paragraph font-s-light not-italic leading-desktop-small text-white"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        )}
        {data.primaryCta && (
          <Box motion={childAnims.buttonGroup} className="flex justify-end">
            <Button className="btn general" link={data.primaryCta} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

ExperienceCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ExperienceCard;
