import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

const DataConsent = ({ className, name, formDefinitionName, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('data-consent flex', className)} {...props}>
      <p
        className="label [&_a]:underline"
        dangerouslySetInnerHTML={{ __html: t(`forms.${formDefinitionName}.$${formDefinitionName}${name}Label`) }}
      />
    </div>
  );
};

export default DataConsent;
