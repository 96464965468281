import AccordionModule from './AccordionModule.jsx';
import AnchorBarModule from './AnchorBarModule.jsx';
import BannerFullBleedModule from './BannerFullBleedModule.jsx';
import BreadcrumbsModel from './BreadcrumbsModel.jsx';
import ItineraryCarouselModule from './ItineraryCarouselModule.jsx';
import CardCarouselGenericModule from './CardCarouselGenericModule/CardCarouselGenericModule.jsx';
import CardListingGridModule from './CardListingGridModule.jsx';
import ContactsModule from './ContactsModule.jsx';
import EmbedModule from './EmbedModule.jsx';
import FooterVertical from './FooterVertical.jsx';
import FormModule from './FormModule.jsx';
import Header from './Header.jsx';
import HeadingVerticalModule from './HeadingVerticalModule.jsx';
import HeroFullBleedModule from './HeroFullBleedModule.jsx';
import HeroFullBleedLeftModule from './HeroFullBleedLeftModule.jsx';
import StatsAndTextModule from './StatsAndTextModule.jsx';
import DetailListModule from './DetailListModule.jsx';
import MapSimpleModule from './MapSimpleModule.jsx';
import MediaCarouselFullBleedModule from './MediaCarouselFullBleedModule.jsx';
import MediaMasonryGridModule from './MediaMasonryGridModule/MediaMasonryGridModule.jsx';
import NewsletterModule from './NewsletterModule.jsx';
import NoHeroModule from './NoHeroModule.jsx';
import QuoteCarouselModule from './QuoteCarouselModule.jsx';
import TextAndMediaModule from './TextAndMediaModule.jsx';
import TextAndTwoMediaModule from './TextAndTwoMediaModule.jsx';
import RichTextModule from './RichTextModule.jsx';
import OffsetRichTextModule from './OffsetRichTextModule.jsx';
import SeasonsCarousel from './SeasonsCarousel/SeasonsCarousel.jsx';
import SocialMediaCarousel from './SocialMediaCarousel.jsx';
import TickerModule from './TickerModule.jsx';

const Modules = {
  AccordionModule,
  AnchorBarModule,
  BannerFullBleedModule,
  BreadcrumbsModel,
  ItineraryCarouselModule,
  CardCarouselGenericModule,
  CardListingGridModule,
  ContactsModule,
  EmbedModule,
  FooterVertical,
  FormModule,
  Header,
  HeadingVerticalModule,
  HeroFullBleedModule,
  HeroFullBleedLeftModule,
  StatsAndTextModule,
  DetailListModule,
  MapSimpleModule,
  MediaCarouselFullBleedModule,
  MediaMasonryGridModule,
  NewsletterModule,
  NoHeroModule,
  QuoteCarouselModule,
  TextAndMediaModule,
  TextAndTwoMediaModule,
  RichTextModule,
  OffsetRichTextModule,
  SeasonsCarousel,
  SocialMediaCarousel,
  TickerModule,
};

export default Modules;
