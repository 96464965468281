import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@/components';
import classNames from 'classnames';
import { getUrlExtension } from '@/utils';
import Pdf from '@/assets/pdf.svg';

const Button = forwardRef(({ className, link, children, ...props }, ref) => {
  const _className = classNames('dupuble-fede-up', className);

  let icon = null;
  if (typeof children !== 'object' && getUrlExtension(link?.href) === 'pdf') {
    icon = <Pdf />;
  }

  if (link) {
    return (
      <Link className={_className} link={link} {...props} ref={ref}>
        <span data-animation={link.text}>{link.text}</span>
        {children} {icon}
      </Link>
    );
  } else if (children) {
    return (
      <button className={_className} {...props} ref={ref}>
        <span data-animation={children}>
          {children} {icon}
        </span>
      </button>
    );
  }
  return null;
});

Button.displayName = 'Button';

Button.propTypes = {
  link: PropTypes.object,
  horizontal: PropTypes.bool,
};

export default Button;
