import React, { useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { ModalPortal, Stack, Box } from '../';
import { modalWrapper, modalBackdrop } from '@/animations';
import { AnimatePresence } from 'framer-motion';
import Close from '@/assets/close.svg';

const FullBleedModal = forwardRef(function FullBleedModal({ onClose, className, children }, ref) {
  const [animateDelay, setAnimateDelay] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open() {
        setOpen(true);
        setAnimateDelay(true);
      },
    };
  }, []);

  const close = () => {
    setAnimateDelay(false);
    setTimeout(() => {
      onClose && onClose();
      setOpen(false);
    }, 500);
  };

  return open ? (
    <ModalPortal>
      <Stack
        motion={modalWrapper}
        className={classNames(
          'disable-scroll fixed top-0 z-50 flex h-screen w-screen flex-col items-center',
          className,
        )}
      >
        <AnimatePresence>
          {animateDelay && (
            <>
              <Box motion={modalBackdrop} className="absolute h-screen w-screen bg-black/90" onClick={close} />
              <Box
                motion={modalBackdrop}
                className="absolute right-4 top-8 z-10 sm:right-8 sm:top-8 lg:right-10 lg:top-10"
              >
                <button className="btn icon dark size-12 bg-white/90 p-0" onClick={close}>
                  <Close role="presentation" aria-label="Close" />
                </button>
              </Box>
              <Box motion={modalBackdrop} className="size-full">
                {children}
              </Box>
            </>
          )}
        </AnimatePresence>
      </Stack>
    </ModalPortal>
  ) : null;
});

export default FullBleedModal;
