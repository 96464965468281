import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ResponsiveImage, Text } from '..';
import classNames from 'classnames';
import { motion } from 'framer-motion';

const transition = {
  type: 'spring',
  damping: 20,
  stiffness: 80,
};

const transitionFast = {
  //type: 'spring',
  damping: 30,
  stiffness: 80,
};

const childAnims = {
  container: {
    variants: {
      cardInactive: {
        margin: 0,
        transition: transitionFast,
      },
      cardActive: {
        margin: 7,
        transition: transitionFast,
      },
    },
  },
  imageWrapper: {
    variants: {
      cardInactive: {
        opacity: 0,
      },
      cardActive: {
        opacity: 1,
        transition: {
          opacity: {
            ...transition,
          },
        },
      },
    },
  },
  description: {
    variants: {
      cardInactive: {
        opacity: 0,
        y: 20,
      },
      cardActive: {
        opacity: 1,
        y: 0,
        transition: {
          opacity: {
            ...transition,
          },
          y: {
            ...transition,
          },
        },
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
const SocialCard = ({ data, className, backgroundColour, ...props }) => {
  const [isHover, setHover] = useState(false);
  return (
    <motion.div
      initial="cardInactive"
      animate={isHover ? 'cardActive' : 'cardInactive'}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={classNames(
        'relative flex flex-col gap-s bg-off-white transition-colors duration-500 md:gap-m',
        className,
      )}
      {...props}
    >
      <Box
        motion={childAnims.container}
        className="image-wrapper relative h-[316px] overflow-hidden supports-[aspect-ratio]:aspect-square supports-[aspect-ratio]:h-[unset]"
      >
        <ResponsiveImage
          image={data.image}
          widths={{ xs: 339, md: 299, lg: 400 }}
          heights={{ xs: 339, md: 299, lg: 400 }}
        />
        <Box
          motion={childAnims.imageWrapper}
          className="absolute left-0 top-0 h-full w-full"
          style={{ background: 'linear-gradient(360deg, rgba(34,31,32,1) 0%, rgba(34,31,32,0) 70%)' }}
        />
      </Box>
      {data.image.caption && (
        <Text
          tag="p"
          motion={childAnims.description}
          className="absolute bottom-5 left-5 w-[217px] text-btn font-s-light not-italic leading-4 text-white"
          dangerouslySetInnerHTML={{ __html: data.image.caption }}
        />
      )}
    </motion.div>
  );
};

SocialCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SocialCard;
