import { ModuleBase, Stack, Box, Text, HeadingTag, ImageCarousel, Button } from '@/components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config';
import { isColorDark } from '@/utils';

const TextAndMediaModule = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const { headingTitle, description, preHeading, subHeading, primaryCta, secondaryCta, images } = data || {};
  const { screens } = tailwindConfig.theme;

  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [screens]);

  const isDark = isColorDark(data.backgroundColour);

  // if (windowWidth >= parseInt(screens.md, 10)) {
  return (
    <ModuleBase data={data}>
      <div className="main-data mobile container flex flex-col px-6 sm:hidden">
        <Stack className="title-wrapper color-from-bg" role="contentinfo" aria-label="contentinfo">
          {preHeading && (
            <Text
              className={classNames(
                'mb-3 font-gotham text-sm font-n-light leading-6',
                isDark ? 'text-white/75' : 'text-black/75',
              )}
            >
              {preHeading}
            </Text>
          )}
          {headingTitle && (
            <HeadingTag
              animate
              data={headingTitle}
              className="color-from-bg mb-8 font-larken text-2xl font-light leading-7"
            />
          )}
          {subHeading && <Text className="">{subHeading}</Text>}
        </Stack>
        <Stack>
          <Box>
            {data.mediaOrientation === 'Portrait' ? (
              <ImageCarousel
                loop
                images={images}
                imageWidths={{ xs: 345, sm: 345, md: 365, lg: 548, xl: 548, xxl: 548 }}
                imageHeights={{ xs: 416, sm: 416, md: 440, lg: 660, xl: 660, xxl: 660 }}
                wrapperClass="w-full aspect-[345/416]"
                isDarkBg={isDark}
              />
            ) : (
              <ImageCarousel
                loop
                images={images}
                imageWidths={{ xs: 345, sm: 345, md: 365, lg: 664, xl: 664, xxl: 664 }}
                imageHeights={{ xs: 230, sm: 230, md: 242, lg: 442, xl: 442, xxl: 442 }}
                wrapperClass="w-full aspect-[345/230]"
                centerCaption
                isDarkBg={isDark}
              />
            )}
          </Box>
        </Stack>
        {(description || primaryCta || secondaryCta) && (
          <Stack className="color-from-bg">
            <Text
              dangerouslySetInnerHTML={{ __html: description }}
              className={classNames(
                'my-8 font-gotham text-sm font-s-light leading-6 [&_p]:opacity-90',
                isDark ? 'text-white/75' : 'text-black/75',
              )}
            />

            {(primaryCta || secondaryCta) && (
              <Box className="flex flex-col gap-4">
                <Button className={classNames('btn secondary w-full', !isDark ? 'dark' : '')} link={primaryCta} />

                <Button className={classNames('btn general w-full', !isDark ? 'dark' : '')} link={secondaryCta} />
              </Box>
            )}
          </Stack>
        )}
      </div>

      <div
        className={classNames(
          'main-data desktop container hidden items-center justify-between gap-6 px-10 sm:flex md:py-6 xl:px-[156px]',
          data.mediaAlignment === 'Left'
            ? 'flex-row-reverse lg:py-0 xxl:justify-end'
            : 'flex-row lg:justify-start lg:py-0',
          data.mediaAlignment === 'Left' && data.mediaOrientation === 'Portrait'
            ? 'xl:gap-xxl xxl:gap-[186px]'
            : 'xl:gap-xl xxl:gap-[148px]',
        )}
      >
        <Stack className="text-wrapper color-from-bg pr-6 sm:w-1/2 xl:w-full xl:max-w-[350px] xl:pr-0 xxl:max-w-[316px]">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <Text
                className={classNames(
                  'mb-3 font-gotham text-sm font-n-light leading-6',
                  isDark ? 'text-white/75' : 'text-black/75',
                )}
              >
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                animate
                data={headingTitle}
                className="color-from-bg mb-8 font-larken text-desktop-medium font-light leading-desktop-medium lg:mb-12"
              />
            )}
            {subHeading && <Text className="">{subHeading}</Text>}
          </div>

          {(description || primaryCta || secondaryCta) && (
            <div className="color-from-bg">
              <Text
                dangerouslySetInnerHTML={{ __html: description }}
                className={classNames(
                  'mb-8 font-gotham text-sm font-s-light leading-6 lg:mb-12 [&_p]:opacity-90',
                  isDark ? 'text-white/75' : 'text-black/75',
                )}
              />

              {(primaryCta || secondaryCta) && (
                <Box className="flex gap-6">
                  <Button className={classNames('btn secondary w-fit', !isDark ? 'dark' : '')} link={primaryCta} />

                  <Button className={classNames('btn general w-fit', !isDark ? 'dark' : '')} link={secondaryCta} />
                </Box>
              )}
            </div>
          )}
        </Stack>

        <Stack className="sm:w-1/2 lg:w-2/3 xl:w-auto">
          <Box>
            {data.mediaOrientation === 'Portrait' ? (
              <ImageCarousel
                loop
                images={images}
                portrait={windowWidth >= parseInt(screens.lg, 10)}
                imageWidths={{ xs: 345, sm: 345, md: 365, lg: 822, xl: 822, xxl: 822 }}
                imageHeights={{ xs: 416, sm: 416, md: 440, lg: 990, xl: 990, xxl: 990 }}
                wrapperClass="h-[440px] lg:h-[660px]"
                wrapperClass2="w-full md:max-w-[365px] lg:w-[548px] lg:max-w-[unset]"
                isDarkBg={isDark}
              />
            ) : (
              <ImageCarousel
                loop
                centerCaption
                images={images}
                imageWidths={{ xs: 345, sm: 345, md: 365, lg: 664, xl: 664, xxl: 664 }}
                imageHeights={{ xs: 230, sm: 230, md: 242, lg: 442, xl: 442, xxl: 442 }}
                wrapperClass="h-[242px] lg:h-[442px]"
                wrapperClass2="w-full md:max-w-[365px] lg:w-[664px] lg:max-w-[unset]"
                isDarkBg={isDark}
              />
            )}
          </Box>
        </Stack>
      </div>
    </ModuleBase>
  );
};

export default TextAndMediaModule;
