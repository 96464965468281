import React, { useRef, useState, useEffect } from 'react';
import { EntityCard, CarouselPagination, Stack, Box } from '@/components';
import { listingGridItem } from '@/animations';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { AnimatePresence } from 'framer-motion';
import { isColorDark } from '@/utils';
import tailwindConfig from '@/tailwind.config.js';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const CarouselGeneric = ({ cardRow, backgroundColour }) => {
  const [align, setAlign] = useState('prev');
  const [panelsPerView, setPanelsPerView] = useState(3);

  const carousel = useRef();

  const isLight = !isColorDark(backgroundColour);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setAlign('center');
        setPanelsPerView(1.1);
      } else if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
        setAlign('prev');
        setPanelsPerView(2.1);
      } else {
        setAlign('prev');
        setPanelsPerView(3);
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <>
      {cardRow?.map((row, rIndex) => (
        <div className="container" key={rIndex}>
          <Stack motion={carouselMotion}>
            <AnimatePresence>
              <Flicking
                ref={carousel}
                panelsPerView={panelsPerView}
                align={align}
                horizontal
                bound
                preventDefaultOnDrag
                className="!overflow-visible"
                resizeOnContentsReady
              >
                {row.props.cards.map((card, index) => (
                  <Box key={index} motion={listingGridItem} className="mx-1.5 flex sm:mx-2">
                    <EntityCard
                      data={card}
                      className="w-full [&_.image-wrapper]:cursor-grab"
                      cardRowModuleName={row.moduleName}
                      backgroundColour={backgroundColour}
                    />
                  </Box>
                ))}
              </Flicking>
            </AnimatePresence>
          </Stack>
          <Stack className="flex justify-center pb-5 pt-12 lg:pb-0 lg:pt-4" role="slider" aria-orientation="horizontal">
            <CarouselPagination
              carousel={carousel}
              dark={isLight}
              className="w-[148px] lg:w-[316px] [&_.btn]:hidden [&_.btn]:lg:block"
              hideIfIrrelevant
            />
          </Stack>
        </div>
      ))}
    </>
  );
};
export default CarouselGeneric;
