import { ModuleBase, Media, Stack, HeadingTag } from '@/components';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';

const BannerFullBleedModule = ({ data }) => {
  const { headingTitle, backgroundMedia } = data || {};

  const darkBg = isColorDark(fixColor(data.backgroundColour));

  return (
    <ModuleBase data={data}>
      <Stack className="relative overflow-hidden px-6 pb-8 pt-6 md:px-10 lg:pb-10 lg:pt-10">
        <div className="h-[194px] w-full md:h-[424px] lg:h-[80vh] lg:min-h-[765px]">
          <Media
            media={backgroundMedia}
            widths={{ xs: 345, md: 1024, lg: 1440, xl: 1900 }}
            cover={
              backgroundMedia.autoPlay ||
              (!backgroundMedia.autoPlay &&
                !backgroundMedia.vimeoId &&
                !backgroundMedia.mobileVimeoId &&
                !backgroundMedia.youtubeId)
            }
            background={data.backgroundMedia.autoPlay}
          />
        </div>
        {headingTitle && headingTitle.heading && (
          <HeadingTag
            animate
            data={headingTitle}
            className={classNames(
              'mt-4 text-center font-gotham text-xs font-n-light leading-tiny-paragraph',
              darkBg ? 'text-white' : 'text-black',
            )}
          />
        )}
      </Stack>
    </ModuleBase>
  );
};

export default BannerFullBleedModule;
