import React, { useRef, useState } from 'react';
import { ModuleBase, Media, Stack, Text, HeadingTag, Link, FullBleedModal, Box } from '@/components';
import PlayIcon from '@/assets/play.svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const HeroFullBleedModule = ({ data }) => {
  const [playing, setPlaying] = useState(false);
  const video = useRef();
  const videoModal = useRef();
  const { t } = useTranslation('common');

  return (
    <ModuleBase data={data} className="relative h-svh overflow-hidden lg:h-screen">
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            ref={video}
            media={data.backgroundMedia}
            widths={{ xs: 425, sm: 768, md: 1024, lg: 1280, xl: 1440, xxl: 2560 }}
            heights={{ xs: 750, sm: null, md: null, lg: null, xl: null, xxl: null }}
            dataNotLazy
            cover
            background={data.backgroundMedia.autoPlay}
            controls={false}
            muted
            className="overlay after:z-20"
            coverImageProps={{ className: classNames('block absolute inset-0 z-10', playing && 'hidden') }}
            preload
            onAutoPlayStarted={() => setPlaying(true)}
            onPlay={() => setPlaying(true)}
          />
        </div>
      )}

      <Stack
        className="container relative z-30 flex h-full flex-col items-center justify-center px-6 text-center lg:px-10"
        role="contentinfo"
      >
        <div className="title-wrapper">
          {data.preHeading && (
            <Text className="text-subheading my-4 font-larken text-base font-semibold uppercase text-white">
              {data.preHeading}
            </Text>
          )}
          {data.headingTitle && (
            <HeadingTag
              motion={{
                variants: {
                  inactive: {
                    y: 50,
                    opacity: 0,
                  },
                  active: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: 'spring',
                      damping: 25,
                      delay: 0.3,
                    },
                  },
                },
              }}
              data={data.headingTitle}
              className="color-from-bg max-w-[922px] font-larken text-desktop-large font-light leading-desktop-large !text-white sm:capitalize md:text-desktop-extra-large md:leading-desktop-extra-large"
            />
          )}
          {data.subHeading && (
            <Text className="text-subheading my-4 font-larken text-base font-semibold text-white">
              {data.subHeading}
            </Text>
          )}
        </div>

        {(data.description || data.primaryCTA || data.secondaryCTA) && (
          <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
            <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

            {(data.primaryCTA || data.secondaryCTA) && (
              <div className="mt-15 flex justify-center gap-4 sm:mt-16 lg:mt-20">
                <Link animate className="btn primary" link={data.primaryCTA} />

                <Link animate className="btn secondary" link={data.secondaryCTA} />
              </div>
            )}
          </div>
        )}

        {(data.backgroundMedia?.vimeoId || data.backgroundMedia.mobileVimeoId) &&
          (data.backgroundMedia.allowFullScreen || (!data.backgroundMedia.autoPlay && !playing)) && (
            <Box className="absolute bottom-9 right-6 sm:bottom-12 sm:right-10 md:bottom-12 lg:bottom-[60px]">
              <button
                onClick={() => (!playing ? video.current.play() : videoModal.current.open())}
                className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75"
              >
                {!data.backgroundMedia.autoPlay && !playing
                  ? t('general.$playVideo')
                  : data.backgroundMedia.allowFullScreen
                    ? t('general.$watchVideo')
                    : null}
                <PlayIcon className="btn icon bg-white/25" role="presentation" />
              </button>
            </Box>
          )}
      </Stack>
      <FullBleedModal ref={videoModal}>
        <div className="size-full p-6">
          <Media
            media={{ ...data.backgroundMedia, autoPlay: true, coverImage: null }}
            className="flex items-center"
            controls
            background={false}
          />
        </div>
      </FullBleedModal>
    </ModuleBase>
  );
};

export default HeroFullBleedModule;
