import { ModuleBase, Button, Text, HeadingTag, Box } from '@/components';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';

const RichTextModule = ({ data }) => {
  const { headingTitle, preHeading, primaryCTA, secondaryCTA } = data || {};

  const isDark = isColorDark(fixColor(data.backgroundColour));

  return (
    <ModuleBase data={data}>
      {preHeading && (
        <Text className="text-subheading container my-4 font-larken text-base font-semibold uppercase text-black">
          {preHeading}
        </Text>
      )}
      {headingTitle && (
        <HeadingTag
          data={headingTitle}
          className="title color-from-bg container mt-7 font-larken text-mobile-large leading-heading2"
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: data.content }} className="container" />

      {(primaryCTA || secondaryCTA) && (
        <Box className="container mt-10 flex justify-start gap-4 overflow-hidden sm:mt-16 lg:mt-10">
          <Button className={classNames('btn primary self-end', !isDark && 'dark')} link={primaryCTA} />
          <Button className={classNames('btn secondary self-end', !isDark && 'dark')} link={secondaryCTA} />
        </Box>
      )}
    </ModuleBase>
  );
};
export default RichTextModule;
