export const stripHtml = (html) => {
  return html?.replace(/(<([^>]+)>)/gi, '');
};

export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrollIntoView = (element, offset = 0) => {
  if (element) {
    element.scrollIntoView();
    window.scrollTo({
      top: element.offsetTop - 110 + offset,
      behavior: 'smooth',
    });
  }
};

export const loadLazyImage = (element) => {
  if (element) {
    element.querySelectorAll('*').forEach((item) => {
      if (item.getAttribute('data-srcset')) {
        item.setAttribute('srcset', item.getAttribute('data-srcset'));
        item.removeAttribute('data-srcset');
      }
      if (item.getAttribute('data-src')) {
        item.setAttribute('src', item.getAttribute('data-src'));
        item.removeAttribute('data-src');
      }
    });
    element.classList.add('lazy-loaded');
  }
};

export const splitWords = (str) => {
  return str.split(' ');
};

export const isFunction = (value) =>
  value
    ? Object.prototype.toString.call(value) === '[object Function]' ||
      'function' === typeof value ||
      value instanceof Function
    : false;

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export const setScrollbarPlaceholder = (size) => {
  const selectors = ['.page-container', 'header .content'];

  selectors.forEach((selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      el.style.paddingRight = size ? `${size}px` : null;
    });
  });
};
