import React, { useState } from 'react';
import { ModuleBase, HeadingTag, Stack, Text, Box } from '@/components';
import ArrowForward from '@/assets/arrowForward.svg';
import classnames from 'classnames';
import { accordionPanel } from '@/animations';
import { motion } from 'framer-motion';
import { isColorDark, fixColor } from '@/utils';
import classNames from 'classnames';

const AccordionModule = ({ data }) => {
  const [activeIndexList, setActiveIndexList] = useState([]);

  const toggle = (index) => {
    let newArray = [...activeIndexList];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      if (data.allowOpeningMultiple) {
        newArray = [index, ...newArray];
      } else {
        newArray = [index];
      }
    }
    setActiveIndexList(newArray);
  };

  const isDark = isColorDark(fixColor(data.backgroundColour));

  return (
    <ModuleBase data={{ ...data }}>
      <div className="container">
        <Stack className="title-wrapper mx-auto mb-8 px-6 md:px-0 lg:mb-10 lg:max-w-[442px]">
          {data.preHeading && (
            <Text
              tag="p"
              className={classNames(
                'mb-3 text-center font-gotham text-sm font-n-light leading-6',
                isDark ? 'text-white/75' : 'text-black/75',
              )}
            >
              {data.preHeading}
            </Text>
          )}
          {data.headingTitle && (
            <HeadingTag
              animate
              data={data.headingTitle}
              className={classNames(
                'text-center font-larken text-desktop-small font-light leading-mobile-medium md:text-desktop-medium md:leading-desktop-medium',
                isDark ? 'text-white' : 'text-black',
              )}
            />
          )}

          {data.description && (
            <Text
              tag="p"
              className={classNames(
                'mt-6 text-center font-gotham text-base font-s-light tracking-paragraph opacity-90',
                isDark ? 'text-white/75' : 'text-black/75',
              )}
            >
              {data.description}
            </Text>
          )}
        </Stack>

        {data.accordionElements && (
          <Stack className="mx-auto text-paragraph lg:max-w-[1128px]">
            {data.accordionElements.map((element, index) => (
              <Box
                key={index}
                className={classnames(
                  'mb-4 py-2 pl-4 transition-all last:mb-0 md:mb-3 md:px-6 md:py-4',
                  activeIndexList.includes(index)
                    ? isDark
                      ? 'bg-white/10 pb-4 md:pb-6'
                      : 'bg-black/10 pb-4 md:pb-6'
                    : isDark
                      ? 'bg-white/[0.05]'
                      : 'bg-black/[0.05]',
                )}
              >
                <button
                  type="button"
                  className="flex w-full items-center justify-between text-left font-larken text-xl leading-6 md:text-desktop-small"
                  onClick={() => toggle(index)}
                >
                  <span className={classNames(isDark ? 'text-white' : 'text-black')}>{element.title}</span>
                  <div className="size-12 p-3">
                    <ArrowForward
                      role="presentation"
                      className={classnames(
                        'm-auto scale-50 transition-transform duration-200',
                        isDark ? 'stroke-white' : 'stroke-black',
                        activeIndexList.includes(index) ? '-rotate-90' : 'rotate-90',
                      )}
                    />
                  </div>
                </button>

                <motion.div
                  variants={accordionPanel.variants}
                  initial="inactive"
                  animate={activeIndexList.includes(index) ? 'active' : 'inactive'}
                  className="pr-4 md:pl-[211px] md:pr-12"
                >
                  {element.text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: element.text }}
                      className={classNames(
                        'richtext-content mx-auto h-full overflow-hidden pt-4 font-gotham text-sm font-s-light leading-6 md:pt-2 lg:w-[548px]',
                        isDark ? 'text-white/75' : 'text-black/75',
                      )}
                    />
                  )}
                </motion.div>
              </Box>
            ))}
          </Stack>
        )}

        {/* <div className="mt-10 flex justify-end gap-4">
          <Link className="btn primary" link={data.primaryCTA} />
          <Link className="btn secondary" link={data.secondaryCTA} />
        </div> */}
      </div>
    </ModuleBase>
  );
};

export default AccordionModule;
