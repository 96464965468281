import React from 'react';
import Image from 'next/image';
import { HeadingTag, ModuleBase, Stack, Text, UmbracoForm, Box } from '@/components';
import NewsletterBg from '@/assets/newsletterBg.png';
import classNames from 'classnames';

// ^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$

const NewsletterModule = ({ data }) => {
  const { headingTitle, description, disclaimer } = data || {};
  return (
    <ModuleBase
      data={{ ...data, paddingTop: 'none', paddingBottom: 'none', backgroundColour: '#16242e' }}
      className="relative overflow-hidden bg-deep-blue py-22 lg:py-20"
    >
      <Image
        className="pointer-events-none absolute bottom-0 left-0 h-full w-full"
        role="presentation"
        src={NewsletterBg}
        alt="presentation"
        priority={100}
      />

      <Stack className="container flex flex-col gap-m px-s lg:gap-14">
        {headingTitle && (
          <HeadingTag
            animate
            data={headingTitle}
            className="text-center font-larken text-desktop-small font-normal not-italic leading-desktop-small text-white"
          />
        )}
        {description && (
          <Text
            className="text-center text-paragraph font-s-light not-italic leading-desktop-small tracking-paragraph text-white/75"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <UmbracoForm
          formDefinition={data?.formFields}
          submitBtnClass="btn secondary"
          formControlClassName="dark"
          successClass="text-center text-white"
          className={classNames(
            'relative [&_.buttons-container]:mt-m [&_.buttons-container]:justify-center [&_.buttons-container]:lg:mt-8 [&_.buttons-container_button]:w-full [&_.buttons-container_button]:sm:w-auto [&_.field-dataConsent_.checkbox]:justify-center',
            '[&_.field-dataConsent_.data-consent]:justify-center [&_.fieldset-column]:sm:max-w-[494px] [&_.fieldset-column]:lg:max-w-[432px] [&_.fieldset]:items-center [&_.fieldset]:justify-center lg:[&_.fieldset]:items-start lg:[&_.fieldsets-container]:gap-10',
            '[&_.field-gAclientID]:hidden [&_.field-syncToSalesforce]:hidden',
          )}
        >
          {disclaimer && (
            <Box className="flex justify-center">
              <div
                className="max-w-[432px] text-center text-btn font-s-light not-italic leading-tiny-paragraph text-white/90 [&_a]:underline"
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              />
            </Box>
          )}
        </UmbracoForm>
      </Stack>
    </ModuleBase>
  );
};
export default NewsletterModule;
