import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { useLayoutEffect } from '@/utils';
import classNames from 'classnames';

const VimeoPlayer = forwardRef(function VimeoPlayer(
  {
    media,
    cover,
    controls,
    onPayerReady,
    onAutoPlayStarted,
    onPlay,
    onFullscreenExit,
    hideVideoUntilPlay,
    background,
    muted,
    ...props
  },
  ref,
) {
  const root = useRef();
  const container = useRef();

  let player = useRef();
  let fullscreen = useRef();
  const [vimeoReady, setVimeoReady] = useState(false);
  const [showlvl1, setShowlvl1] = useState(!cover);
  const [showlvl2, setShowlvl2] = useState(!cover);
  const [videobgWidth, setVideobgWidth] = useState('100%');
  const [videobgHeight, setVideobgHeight] = useState('100%');

  useEffect(() => {
    const id = (window.innerWidth < 768 ? media.mobileVimeoId : media.vimeoId) || media.vimeoId || media.mobileVimeoId;

    if (!isNaN(id)) {
      player.current = new Player(container.current, {
        id,
        autoplay: media.autoPlay,
        background: background,
        loop: media.loop,
        controls: controls,
        muted: muted === undefined ? false : muted,
        dnt: true,
        pip: false,
      });

      if (media.autoPlay && onAutoPlayStarted) {
        player.current.play().then(() => {
          onAutoPlayStarted();
        });
      }

      if (onPlay) {
        player.current.on('play', onPlay);
      }

      player.current.ready().then(() => {
        setVimeoReady(true);
        if (onPayerReady) {
          onPayerReady();
        }
      });
    } else {
      console.error(`'${id}' is not a valid vimeo ID`);
    }
  }, [onPayerReady, onAutoPlayStarted, media, controls, cover, background, muted, onPlay]);

  useImperativeHandle(ref, () => {
    const monitorFullScreen = () => {
      if (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) {
        setTimeout(() => {
          monitorFullScreen();
        }, 100);
      } else {
        if (fullscreen.current) {
          fullscreen.current = false;
        }
        onFullscreenExit && onFullscreenExit();
      }
    };

    return {
      openFullscreen() {
        if (
          (media.allowFullScreen === undefined || media.allowFullScreen === true) &&
          !fullscreen.current &&
          player.current
        ) {
          fullscreen.current = true;
          player.current.requestFullscreen();
          player.current.play();
          setTimeout(() => {
            monitorFullScreen();
          }, 1000);
        }
      },
      play() {
        setShowlvl2(true);
        player.current?.play().catch((e) => {
          console.warn(e);
        });
      },
      pause() {
        player.current?.pause().catch((e) => {
          console.warn(e);
        });
      },
      unMute() {
        player.current?.setMuted(false);
      },
      mute() {
        player.current?.setMuted(true);
      },
    };
  }, [media, onFullscreenExit]);

  useLayoutEffect(() => {
    const videobgEnlarge = () => {
      if (root.current.getElementsByTagName('iframe') && root.current.getElementsByTagName('iframe')[0]) {
        const iframe = root.current.getElementsByTagName('iframe')[0];
        const videoAspect = iframe.height / iframe.width;
        const parentAspect = root.current.parentElement.offsetHeight / root.current.parentElement.offsetWidth;

        if (parentAspect > videoAspect) {
          setVideobgWidth((parentAspect / videoAspect) * 100 + '%');
          setVideobgHeight('100%');
        } else {
          setVideobgWidth('100%');
          setVideobgHeight((videoAspect / parentAspect) * 100 + '%');
        }
      }
      setShowlvl1(true);
      setShowlvl2(!hideVideoUntilPlay);
    };
    if (vimeoReady && cover) {
      videobgEnlarge();
      window.addEventListener('resize', videobgEnlarge);
      return () => window.removeEventListener('resize', videobgEnlarge);
    }
  }, [vimeoReady, cover, media.autoPlay, hideVideoUntilPlay]);

  if (!media) {
    return null;
  }

  return (
    <div
      ref={root}
      className={classNames(
        'absolute left-1/2 top-1/2 h-full w-full -translate-x-2/4 -translate-y-2/4',
        !showlvl1 && 'hidden',
        !showlvl2 && 'invisible',
      )}
      style={{ width: videobgWidth, height: videobgHeight }}
    >
      <div
        className="vimeo h-full w-full [&_iframe]:h-full [&_iframe]:w-full"
        ref={container}
        {...props}
        aria-label="Embedded Vimeo video player"
      />
    </div>
  );
});

VimeoPlayer.propTypes = {
  media: PropTypes.object.isRequired,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
};

export default VimeoPlayer;
