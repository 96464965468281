import React, { useState, useEffect } from 'react';
import { ResponsiveImage } from '@/components';
import classNames from 'classnames';

const NavBgImage = ({ header, currentBgImage }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const headerNavImages = [];

    const actualType = (o) => {
      return Object.prototype.toString.apply(o);
    };

    const getAllNavImages = (data, key, level) => {
      if (actualType(data) == actualType({}) || actualType(data) == actualType([])) {
        for (var j = 0; j < Object.keys(data).length; j++) {
          if (Object.keys(data)[j] === key && Object.values(data)[j] !== null) {
            if (!headerNavImages.find((image) => image.image.mediaId === data[Object.keys(data)[j]].mediaId)) {
              headerNavImages.push({ image: data[Object.keys(data)[j]], mobile: headerNavImages.length === 0 });
            }
          } else getAllNavImages(data[Object.keys(data)[j]], key, level + 1);
        }
      }
    };

    getAllNavImages(header, 'navItemBackgroundImage', 0);
    setImages(headerNavImages);
  }, [header]);

  return (
    <div className="pointer-events-none relative h-full w-full">
      {images?.map((image) => (
        <ResponsiveImage
          key={image.image.mediaId}
          image={image.image}
          widths={{
            xs: image.mobile ? 425 : 1,
            sm: image.mobile ? 640 : 1,
            md: image.mobile ? 768 : 1,
            lg: 1024,
            xl: 1280,
            xxl: 2560,
          }}
          heights={{ xs: image.mobile ? 700 : 1, sm: image.mobile ? null : 1, md: image.mobile ? null : 1, lg: null }}
          imgProps={{}}
          role="presentation"
          className={classNames(
            'absolute left-0 top-0 h-screen w-full object-cover transition-opacity duration-500',
            image.mobile ? 'opacity-100' : 'hidden lg:block',
            currentBgImage?.mediaId === image.image.mediaId ? 'lg:opacity-100' : 'lg:opacity-0',
          )}
          aria-hidden
        />
      ))}
    </div>
  );
};

export default NavBgImage;
