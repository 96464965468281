import { Link } from '@/components';
import NavColumn from './NavColumn';
import classNames from 'classnames';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import tailwindConfig from '@/tailwind.config.js';
import { openLink, convertAbsoluteLinkToRelative } from '@/utils';
import { useRouter } from 'next/router';

const NavItem = ({
  navItem,
  level,
  activeMenu,
  setActiveMenu,
  close,
  setBackgroundImage,
  parentBackgroundImage,
  title,
}) => {
  const router = useRouter();

  const _setActiveMenu = (sub) => {
    if (setActiveMenu) {
      let newValue = {};
      newValue[`l${level + 1}`] = sub;
      setActiveMenu(newValue);
    }
  };

  const flyoutMenuClick = (navItem) => {
    _setActiveMenu(navItem);

    if (window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10) && navItem.navItemLink) {
      openLink(router, convertAbsoluteLinkToRelative(navItem.navItemLink));
      close();
    }
  };

  const linkClass = classNames(
    'group font-s-light  lg:leading-large-paragraph lg:text-xl not-italic font-gotham cursor-pointer items-center lg:justify-between duration-500 [&_svg]:duration-500',
    'text-white hover:text-white [&_svg]:stroke-white [&_svg]:hover:stroke-white',
    activeMenu && activeMenu[`l${level + 1}`]
      ? 'lg:text-white lg:[&_svg]:stroke-white'
      : 'lg:text-white/50 lg:[&_svg]:stroke-white/50',
    title
      ? 'text-paragraph leading-desktop-small tracking-paragraph justify-between min-h-12 lg:min-h-0'
      : 'text-desktop-small leading-large-paragraph justify-center',
  );

  const navText = (
    <>
      <div className="flex flex-col">
        {navItem?.navItemText}
        {navItem?.navItemSubText && (
          <span className="text-small-paragraph font-n-light not-italic leading-desktop-small">
            {navItem.navItemSubText}
          </span>
        )}
      </div>
    </>
  );
  return (
    <>
      <div
        className={classNames(
          title &&
            'border-px border-b border-dashed border-white/25 py-xs first:border-t lg:border-0 lg:py-0 first:lg:border-t-0',
        )}
      >
        {navItem?.navItemLink && (!navItem?.flyout || navItem?.flyout?.navItems?.length === 0) ? (
          <Link
            className={classNames('flex lg:max-w-fit', linkClass)}
            link={navItem?.navItemLink}
            onClick={close}
            onMouseEnter={() => {
              setBackgroundImage(navItem.navItemBackgroundImage || parentBackgroundImage);
              _setActiveMenu(navItem);
            }}
          >
            {navText}
          </Link>
        ) : (
          <div
            tabIndex="0"
            className={classNames('flex', linkClass)}
            onMouseEnter={() => {
              setBackgroundImage(navItem.navItemBackgroundImage || parentBackgroundImage);
              _setActiveMenu(navItem);
            }}
            onFocus={() => {
              setBackgroundImage(navItem.navItemBackgroundImage || parentBackgroundImage);
              _setActiveMenu(navItem);
            }}
            onClick={() => flyoutMenuClick(navItem)}
            aria-label={navItem?.navItemText}
          >
            {navText}
            {navItem?.flyout?.navItems?.length > 0 && (
              <DropdownArrow
                className={classNames('mr-3 -rotate-90', !title && 'hidden lg:block')}
                role="presentation"
              />
            )}
          </div>
        )}
      </div>
      {navItem?.flyout?.navItems?.length > 0 && (
        <NavColumn
          navItems={navItem.flyout.navItems}
          cta={navItem.flyout.navCta}
          level={level + 1}
          activeMenu={activeMenu}
          setActiveMenu={(sub) => _setActiveMenu(sub)}
          close={close}
          title={navItem.navItemText}
          backButton
          setBackgroundImage={setBackgroundImage}
          parentBackgroundImage={navItem.navItemBackgroundImage || parentBackgroundImage}
        />
      )}
    </>
  );
};

export default NavItem;
