import React, { useState, useEffect } from 'react';
import DropdownFilter from './DropdownFilter';
import DropdownFilterMobile from './DropdownFilterMobile';
import { pushValuesToRouteQuery, cleanQueryData, assignValuesFromRouteQuery } from '@/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Box } from '@/components';
import { animControllerInView } from '@/animations';
import { useTranslation } from 'next-i18next';
import Close from '@/assets/close.svg';

const SearchFilters = ({ filters, onChange, queryMode, className, dark, ...props }) => {
  const [filterValues, setFilterValues] = useState();
  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    if (filters) {
      let newFilterValues = { ...filterValues };
      if (!filterValues) {
        filters.forEach((element) => {
          newFilterValues[element.filterFieldName] = [];
        });
      }
      assignValuesFromRouteQuery(router, newFilterValues);
      setFilterValues(newFilterValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, router.query]);

  const _onChange = (value) => {
    pushFiltersToRoute({ ...filterValues, ...value });
  };

  const pushFiltersToRoute = (newFilterValues) => {
    const newQueryParams = {
      ...newFilterValues,
      page: null,
    };

    if (queryMode) {
      pushValuesToRouteQuery(router, newQueryParams);
    } else if (onChange) {
      cleanQueryData(newQueryParams);
      onChange(newQueryParams);
    }
  };

  const clearAll = () => {
    const newFilterValues = {};
    for (const [key] of Object.entries(filterValues)) {
      newFilterValues[key] = [];
    }
    _onChange(newFilterValues);
  };

  if (!filters) return;

  const conClassName = classNames(
    dark
      ? '[&_.open_.popover-title]:text-black/75 [&_.popover-title]:text-white/75 [&_.content]:!border-white'
      : '[&_.popover-title]:text-black/75 [&_.content]:!border-black/25',
    dark ? '[&_.main-button]:border-white/25 [&_.open_.main-button]:bg-white' : '[&_.main-button]:border-black/25',
    'w-full sm:w-auto [&_.dropdown.open]:z-10 [&_.main-button]:rounded-sm [&_.main-button]:border ',
    '[&_.main-button]:transition-all',
    '[&_.dropdown.open.bottom_.content]:!border-t-0 [&_.dropdown.open.top_.content]:!border-b-0',
    '[&_.dropdown.open.bottom_.main-button]:rounded-b-none [&_.dropdown.open.bottom_.main-button]:border-b-transparent',
    '[&_.dropdown.open.top_.main-button]:rounded-t-none [&_.dropdown.open.top_.main-button]:border-t-transparent',
    '[&_.content]:rounded-sm [&_.dropdown.open.bottom_.content]:rounded-t-none [&_.dropdown.open.top_.content]:rounded-b-none',
  );

  const filtersCount =
    filterValues &&
    Object.entries(filterValues)
      // eslint-disable-next-line no-unused-vars
      .map(([key, value]) => value.length)
      .reduce((a, b) => a + b, 0);

  return (
    <>
      <div className={classNames('hidden items-center justify-between lg:flex', className)} {...props}>
        <div className="flex flex-row items-center gap-xs">
          {filterValues &&
            filters.map((filter, index) => (
              <Box motion={animControllerInView({ amount: 0.2 })} key={index} className={conClassName}>
                <DropdownFilter
                  className={classNames('input dropdown w-full rounded-sm md:min-w-52 lg:w-60', dark && 'dark')}
                  value={filterValues[filter.filterFieldName]}
                  filter={filter}
                  onChange={_onChange}
                />
              </Box>
            ))}
        </div>
        {filtersCount > 0 && (
          <div>
            <button
              onClick={clearAll}
              className={classNames(
                'flex items-center gap-xxs text-btn font-normal uppercase not-italic leading-tiny-paragraph',
                dark ? 'text-white' : 'text-black',
              )}
            >
              <Close className={classNames(dark ? 'fill-white' : 'fill-black')} />
              {t('general.$clearfilters', { count: filtersCount })}
            </button>
          </div>
        )}
      </div>
      <div className={classNames('lg:hidden', className)}>
        <Box className={conClassName}>
          <DropdownFilterMobile
            className={classNames('input dropdown w-full rounded-sm sm:w-60 md:min-w-52', dark && 'dark')}
            filterValues={filterValues}
            filters={filters}
            onChange={_onChange}
            clearAll={clearAll}
            dark={dark}
          />
        </Box>
      </div>
    </>
  );
};
export default SearchFilters;
