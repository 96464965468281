import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, ResponsiveImage, Stack, Button } from '..';
import classNames from 'classnames';

import { fadeInDown, animControllerInView, fadeIn } from '@/animations';
import tailwindConfig from '@/tailwind.config.js';
import { useTranslation } from 'next-i18next';
import ExperienceCard from './ExperienceCard';

// eslint-disable-next-line no-unused-vars
const ExperienceCardLarge = ({ data, className, backgroundColour, ...props }) => {
  const [mobile, setMobile] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    const resize = () => {
      setMobile(window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <>
      <Stack
        animate={!mobile}
        motion={animControllerInView({ once: false, amount: 0.1 })}
        className={classNames('relative hidden w-full flex-col gap-xxs lg:flex', className)}
        {...props}
      >
        <div className="image-wrapper aspect-[377/246] max-h-[700px] w-full sm:aspect-auto sm:h-[85svh]">
          <ResponsiveImage
            motion={fadeIn}
            image={data.image}
            widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
            heights={{ xs: 277, sm: 640, md: 768, lg: 438, xl: 548, xxl: 1070 }}
          />
        </div>

        {data.preHeading && (
          <Box
            motion={fadeInDown}
            className="absolute right-5 top-5 flex items-center gap-xs rounded-full bg-black/25 px-xs py-3 text-white backdrop-blur-[22px]"
          >
            <p className="text-btn font-n-light not-italic leading-tiny-paragraph">{data.preHeading}</p>
          </Box>
        )}

        <Box className="bottom-5 right-5 flex h-full w-full flex-col justify-between gap-4 bg-black/50 p-4 backdrop-blur-[22px] sm:absolute sm:h-auto sm:w-[416px] md:gap-5 md:px-5 md:pb-5 md:pt-8">
          <div className="flex flex-col gap-4 md:gap-5">
            {data.date && (
              <p className="text-small-paragraph font-n-light not-italic leading-desktop-small text-white/50">
                {data.date}
              </p>
            )}
            {data.headingTitle && (
              <p
                role="heading"
                aria-level="3"
                className="font-larken text-xl font-normal not-italic leading-desktop-small text-white"
              >
                {data.headingTitle}
              </p>
            )}
            {data.description && (
              <div
                className="text-small-paragraph font-s-light not-italic leading-desktop-small text-white"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
          </div>
          {data.primaryCta && (
            <div className="flex justify-end">
              <Button
                className="btn general"
                link={{ ...data.primaryCta, text: t('general.$readArticle', { label: data.preHeading }) }}
                role="button"
              />
            </div>
          )}
        </Box>
      </Stack>
      <ExperienceCard data={data} className="lg:hidden" backgroundColour={backgroundColour} />
    </>
  );
};

ExperienceCardLarge.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ExperienceCardLarge;
