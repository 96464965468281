import React, { useEffect, useState, useRef } from 'react';
import { PopoverMenu } from '../index';
import classNames from 'classnames';

const Dropdown = ({ name, options, value, placeholder, onChange, onBlur, displayEmpty, className, ...props }) => {
  const [open, setOpen] = useState(false);
  const [_value, set_value] = useState(value);
  const [title, setTitle] = useState();
  const wrapper = useRef();

  const _onChange = (option) => {
    set_value(option.value);
    const event = { target: { name, value: option.value } };
    if (onChange) {
      onChange(event);
    }
    setOpen(false);
    if (onBlur) {
      setTimeout(() => {
        onBlur(event);
      }, 0);
    }
  };

  useEffect(() => {
    if (_value && options) {
      const option = options.find((option) => option.value === _value);
      setTitle(option?.label);
    }
  }, [_value, options]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      set_value(value);
    } else {
      set_value(null);
      setTitle(null);
    }
  }, [value]);

  useEffect(() => {
    const keydown = (event) => {
      const focusOption = wrapper.current.querySelector('.option:focus');
      switch (event.key) {
        case 'ArrowUp':
          console.log('ArrowUp');
          event.preventDefault();
          focusOption?.previousElementSibling?.focus();
          break;
        case 'ArrowDown':
          event.preventDefault();
          focusOption?.nextElementSibling?.focus();
          break;
        case 'Enter':
          event.preventDefault();
          focusOption?.click();
          break;
      }
    };

    if (open) {
      window.addEventListener('keydown', keydown);
    } else {
      window.removeEventListener('keydown', keydown);
    }

    return () => window.removeEventListener('keydown', keydown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <PopoverMenu
      open={open}
      title={title || placeholder}
      titleClass={!title && placeholder && 'placeholder'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className={classNames(title && 'selected', className)}
      {...props}
    >
      <ul className="text-gray-700 dark:text-gray-200 w-full py-2 text-sm" ref={wrapper}>
        {displayEmpty && (
          <li onClick={() => _onChange({})} className="option" tabIndex={open ? '0' : null}>
            <div className="cursor-pointe block px-4 py-2">&nbsp;</div>
          </li>
        )}
        {options &&
          options.map((option) => (
            <li key={option.value} onClick={() => _onChange(option)} className="option" tabIndex={open ? '0' : null}>
              <div
                className="block cursor-pointer px-4 py-2"
                dangerouslySetInnerHTML={{ __html: option.label?.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') }}
              />
            </li>
          ))}
      </ul>
    </PopoverMenu>
  );
};

export default Dropdown;
