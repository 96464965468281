import { ModuleBase, Button, Text, HeadingTag, Box, Stack } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const HeadingVerticalModule = ({ data }) => {
  const { headingTitle, description, subheading, primaryCTA, secondaryCTA } = data || {};

  return (
    <ModuleBase data={data}>
      <Stack className="container px-6 py-4 md:px-10 md:py-6 lg:px-[272px] lg:py-8">
        <div className="text-wrapper">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {headingTitle && headingTitle.heading && (
              <HeadingTag
                animate
                data={headingTitle}
                className="color-from-bg mb-10 text-center font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium"
              />
            )}
            {subheading && (
              <Text
                className={classNames(
                  'mb-4 text-center font-gotham text-sm font-s-light leading-4 md:mb-3 [&_strong]:font-n-light',
                  isColorDark(data.backgroundColour) ? 'text-white/50' : 'text-black/50',
                )}
                dangerouslySetInnerHTML={{ __html: subheading }}
              ></Text>
            )}
          </div>

          {(description || primaryCTA || secondaryCTA) && (
            <div className={isColorDark(data.backgroundColour) ? 'text-white/75' : 'text-black/75'}>
              <Text
                role="heading"
                aria-level="4"
                dangerouslySetInnerHTML={{ __html: description }}
                className="text-center font-gotham text-base font-s-light tracking-paragraph md:text-2xl"
              />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-10 flex w-full flex-col gap-4 md:flex-row md:justify-center md:gap-6">
                  <Button
                    className={classNames('btn secondary', !isColorDark(data.backgroundColour) ? 'dark' : '')}
                    link={primaryCTA}
                  />

                  <Button
                    className={classNames('btn general', !isColorDark(data.backgroundColour) ? 'dark' : '')}
                    link={secondaryCTA}
                  />
                </Box>
              )}
            </div>
          )}
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default HeadingVerticalModule;
