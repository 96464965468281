import PropTypes from 'prop-types';
import ArticleCardLarge from './ArticleCardLarge';
import ArticleCard from './ArticleCard';
import ExperienceCard from './ExperienceCard';
import ExperienceCardLarge from './ExperienceCardLarge';

import GenericCard from './GenericCard';
import SocialCard from './SocialCard';
import FeatureCard from './FeatureCard';
import LodgeCard from './LodgeCard';
import LodgeCardLarge from './LodgeCardLarge';
import PersonCardLarge from './PersonCardLarge';
import PersonCard from './PersonCard';
import FullBleedHotelCard from './FullBleedHotelCard';
import classNames from 'classnames';

const EntityCard = ({ data, cardRowModuleName, className, large, ...props }) => {
  let _props = { ...props };
  let Component = null;
  if (
    data.moduleName === 'ExperienceCard' ||
    (data.moduleName === 'GenericCardModel' && cardRowModuleName === 'CustomCardRowModel')
  ) {
    Component = large ? ExperienceCardLarge : ExperienceCard;
  } else if (data.moduleName === 'SocialCardModel') {
    Component = SocialCard;
  } else if (data.moduleName === 'FeatureCardModel') {
    Component = FeatureCard;
  } else if (data.moduleName === 'FullBleedHotelCard' || data.moduleName === 'FullBleedCardModel') {
    Component = FullBleedHotelCard;
  } else if (data.moduleName === 'LodgeCard') {
    Component = large ? LodgeCardLarge : LodgeCard;
  } else if (data.moduleName === 'ArticleCard') {
    Component = large ? ArticleCardLarge : ArticleCard;
  } else if (data.moduleName === 'PersonCard') {
    Component = large ? PersonCardLarge : PersonCard;
  } else {
    Component = large ? FeatureCard : GenericCard;
  }

  return Component ? (
    <Component data={data.props} className={classNames(className, `${data.moduleName}`)} {..._props} />
  ) : (
    data.moduleName + ' card not implemented'
  );
};

EntityCard.propTypes = {
  data: PropTypes.object.isRequired,
  large: PropTypes.bool,
};

export default EntityCard;
