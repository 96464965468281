import classNames from 'classnames';
import { motion, useMotionValue, wrap } from 'framer-motion';
import { useState } from 'react';
import { ResponsiveImage } from '..';
import { imageCarouselAnimationDefault } from '@/animations';

const ImageCarousel = ({
  loop = false,
  images,
  imageWidths,
  imageHeights,
  wrapperClass,
  wrapperClass2,
  portrait = false,
  centerCaption,
  isDarkBg = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const dragX = useMotionValue(0);
  const dragY = useMotionValue(0);
  let imageIndex;

  const paginate = (direction) => {
    setCurrentIndex((prev) => prev + direction);
  };

  if (loop) {
    imageIndex = wrap(0, images.length, currentIndex);
  } else {
    imageIndex = currentIndex;
  }

  const onDragEndPortrait = () => {
    const y = dragY.get();

    if (loop) {
      if (y <= -50) {
        paginate(1);
      } else if (y >= 50) {
        paginate(-1);
      }
    } else {
      if (y <= -50 && currentIndex < images.length - 1) {
        paginate(1);
      } else if (y >= 50 && currentIndex > 0) {
        paginate(-1);
      }
    }
  };

  const onDragEndLandscape = () => {
    const x = dragX.get();

    if (loop) {
      if (x <= -50) {
        paginate(1);
      } else if (x >= 50) {
        paginate(-1);
      }
    } else {
      if (x <= -50 && currentIndex < images.length - 1) {
        paginate(1);
      } else if (x >= 50 && currentIndex > 0) {
        paginate(-1);
      }
    }
  };

  const imagePanels = images.map((image, index) => (
    <div key={`image-${index}`} className={classNames('shrink-0', wrapperClass, wrapperClass2)}>
      <ResponsiveImage
        image={image}
        widths={imageWidths}
        heights={imageHeights}
        className="pointer-events-none block size-full select-none object-cover"
      />
    </div>
  ));

  const isCaption = images?.find((image) => image?.caption);

  if (portrait) {
    return (
      <div className="flex flex-col lg:flex-row">
        <div className="carousel-wrapper">
          <div className={classNames('relative overflow-hidden', wrapperClass, wrapperClass2)}>
            {images.length > 1 ? (
              <motion.div
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                style={{ y: dragY }}
                animate={{ translateY: `-${(imageIndex * 100) / images.length}%` }}
                transition={imageCarouselAnimationDefault}
                whileTap={{ cursor: 'grabbing' }}
                onDragEnd={onDragEndPortrait}
                className="flex cursor-grab flex-col"
              >
                {imagePanels}
              </motion.div>
            ) : (
              <div>{imagePanels}</div>
            )}
          </div>

          {isCaption && (
            <p
              className={classNames(
                'color-from-bg mt-4 line-clamp-2 min-h-12 font-gotham text-sm font-n-light leading-6 sm:mt-xs lg:mt-3',
                wrapperClass2,
              )}
            >
              {images[imageIndex].caption}
            </p>
          )}
        </div>

        {images.length > 1 && (
          <ImageCarouselPagination
            images={images}
            imageIndex={imageIndex}
            setCurrentIndex={setCurrentIndex}
            wrapperClasses="pagination flex ml-2 lg:flex-col justify-center gap-1 overflow-y-auto"
            imageWrapperClasses="h-[65px] w-[66px] md:h-[70px] md:w-[70px] lg:h-[84px]"
            imageWidths={{ xs: 66, sm: 66, md: 70, lg: 70, xl: 70, xxl: 70 }}
            imageHeights={{ xs: 65, sm: 65, md: 70, lg: 84, xl: 84, xxl: 84 }}
            isDarkBg={isDarkBg}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col">
        <div className="carousel-wrapper">
          <div className={classNames('relative overflow-hidden', wrapperClass, wrapperClass2)}>
            {images.length > 1 ? (
              <motion.div
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                style={{ x: dragX }}
                animate={{ translateX: `-${imageIndex * 100}%` }}
                transition={imageCarouselAnimationDefault}
                whileTap={{ cursor: 'grabbing' }}
                onDragEnd={onDragEndLandscape}
                className="flex cursor-grab"
              >
                {imagePanels}
              </motion.div>
            ) : (
              <div>{imagePanels}</div>
            )}
          </div>

          {images.length > 1 && (
            <ImageCarouselPagination
              images={images}
              imageIndex={imageIndex}
              setCurrentIndex={setCurrentIndex}
              wrapperClasses="pagination mt-2 flex gap-1 overflow-auto lg:justify-center"
              imageWrapperClasses="h-[65px] w-[66px] md:h-[70px] md:w-[70px] lg:h-[62px] lg:w-[84px]"
              imageWidths={{ xs: 66, sm: 66, md: 70, lg: 84, xl: 84, xxl: 84 }}
              imageHeights={{ xs: 65, sm: 65, md: 70, lg: 62, xl: 62, xxl: 62 }}
              isDarkBg={isDarkBg}
            />
          )}

          {isCaption && (
            <p
              className={classNames(
                'color-from-bg mt-4 line-clamp-2 min-h-12 font-gotham text-sm font-n-light leading-6 sm:mt-xs lg:mt-6',
                wrapperClass2,
                centerCaption ? 'text-center' : '',
              )}
            >
              {images[imageIndex]?.caption}
            </p>
          )}
        </div>
      </div>
    );
  }
};

const ImageCarouselPagination = ({
  images,
  imageIndex,
  imageWidths,
  imageHeights,
  wrapperClasses,
  imageWrapperClasses,
  setCurrentIndex,
  isDarkBg = false,
}) => {
  return (
    <div className={wrapperClasses}>
      {images.map((image, index) => (
        <div
          aria-label={`Go to slide ${index + 1}`}
          key={`pagination-i-${index}`}
          className={classNames(
            'cursor-pointer border transition-all duration-500',
            imageIndex === index ? (isDarkBg ? 'border-white' : 'border-black') : 'border-transparent',
            imageWrapperClasses,
          )}
          onClick={() => setCurrentIndex(index)}
        >
          <ResponsiveImage
            image={image}
            widths={imageWidths}
            heights={imageHeights}
            className={classNames(
              'relative block size-full object-cover transition-opacity',
              index !== imageIndex ? 'overlay opacity-50' : '',
            )}
            imgProps={{ tabIndex: 0 }}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageCarousel;
