import { ModuleBase, Link, Button } from '@/components';
import PropTypes from 'prop-types';
import Instagram from '@/assets/instagram.svg';
import Facebook from '@/assets/facebook.svg';
import Linkedin from '@/assets/linkedin.svg';
import Youtube from '@/assets/youtube.svg';
import Twitter from '@/assets/twitterBird.svg';
import ArrowForward from '@/assets/arrowForward.svg';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { accordionPanel } from '@/animations';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import ElevenLogoFooter from '@/assets/elevenLogoFooter.svg';

const FooterVertical = ({ data }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <FooterVerticalMobile data={data} />

      <ModuleBase
        data={data}
        className="hidden bg-dark-blue pb-[19px] pt-[50px] font-gotham font-s-light lg:block"
        role="contentinfo"
        aria-label="footer"
        hTag="footer"
      >
        <div className="upper-section container flex items-start justify-between px-10">
          <div className="links-wrapper flex gap-8">
            {data.linkColumns &&
              data.linkColumns.map((col, index) => (
                <div key={`column-${index}`}>
                  <p className="mb-4 text-base tracking-paragraph text-white">{col.columnHeading}</p>
                  <div className="grid auto-cols-fr grid-flow-col grid-rows-6 gap-x-8">
                    {col.links.map((link, index) => (
                      <div
                        key={`#${col.columnHeading}-link-${index}`}
                        className="min-w-[200px] py-1 text-sm leading-6 text-white/75 transition-all hover:-translate-x-px hover:text-bright-orange"
                      >
                        <Link link={link} className="whitespace-nowrap" />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          {data.displayContactDetails && (
            <div className="flex flex-col">
              <p className="mb-4 text-base tracking-paragraph text-white/90">{data.contactsLabel}</p>

              <a
                href={`tel:${data.phoneNumber}`}
                className="py-1 text-sm leading-6 text-white/50 transition-all hover:-translate-x-px hover:text-bright-orange"
              >
                {data.phoneNumber}
              </a>

              <a
                href={`mailto:${data.emailAddress}`}
                className="py-1 text-sm leading-6 text-white/50 transition-all hover:-translate-x-px hover:text-bright-orange"
              >
                {data.emailAddress}
              </a>

              <Button link={{ href: '/', text: t('general.$getinTouch') }} className="btn primary dark mt-[22px]" />
            </div>
          )}
        </div>

        <hr className="mt-[61px] w-full border-dashed border-white/25" />

        <div className="middle-section container flex items-center justify-between px-10">
          <div className="logo-wrapper my-[29px] h-[29px] w-[127px]">
            <ElevenLogoFooter className="fill-white" role="presentation" />
          </div>
          {data.displaySocialLinks && (
            <div className="social-wrapper flex items-center gap-6">
              {data.socialLinks.map((socialItem, socialIndex) => (
                <div className="text-white/75" key={socialIndex}>
                  {socialItem.socialPlatform.toLowerCase() === 'facebook' ? (
                    <Link link={socialItem.link} aria-label="Facebook" className="block size-6 [&_path]:fill-current">
                      <Facebook />
                    </Link>
                  ) : socialItem.socialPlatform.toLowerCase() === 'instagram' ? (
                    <Link link={socialItem.link} aria-label="Instagram" className="block size-6 [&_path]:fill-current">
                      <Instagram />
                    </Link>
                  ) : socialItem.socialPlatform.toLowerCase() === 'linkedin' ? (
                    <Link link={socialItem.link} aria-label="Linkedin">
                      <Linkedin />
                    </Link>
                  ) : socialItem.socialPlatform.toLowerCase() === 'youtube' ? (
                    <Link link={socialItem.link} aria-label="Youtube" className="block size-6 [&_path]:fill-current">
                      <Youtube />
                    </Link>
                  ) : socialItem.socialPlatform.toLowerCase() === 'twitter' ? (
                    <Link link={socialItem.link} aria-label="Twitter" className="block size-6 [&_path]:fill-current">
                      <Twitter />
                    </Link>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>

        <hr className="w-full border-dashed border-white/25" />

        <div className="bottom-section container mt-[18px] flex items-center justify-between px-10">
          <div className="flex gap-6">
            {data.bottomLinks.map((bottomLinkItem, bottomLinkIndex) => (
              <div
                className="bottom-link-item text-xs leading-tiny-paragraph text-white/75 transition-all hover:-translate-x-px hover:text-bright-orange"
                key={bottomLinkIndex}
              >
                <Link link={bottomLinkItem} />
              </div>
            ))}
          </div>
          <p className="text-xs leading-tiny-paragraph text-white/75">{data.copyrightText}</p>
        </div>
      </ModuleBase>
    </>
  );
};

const FooterVerticalMobile = ({ data }) => {
  const [activeIndexList, setActiveIndexList] = useState([]);
  const { t } = useTranslation('common');

  const toggle = (index) => {
    let newArray = [...activeIndexList];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      newArray = [index, ...newArray];
    }
    setActiveIndexList(newArray);
  };

  return (
    <ModuleBase
      data={data}
      className="container flex flex-col items-center gap-8 bg-dark-blue px-0 pb-4 pt-12 font-gotham font-s-light lg:hidden"
      role="contentinfo"
      aria-label="footer"
      hTag="footer"
    >
      <div className="logo-wrapper h-[29px] w-[127px]">
        <ElevenLogoFooter className="fill-white" role="presentation" />
      </div>

      {data.linkColumns && (
        <div className="links-wrapper flex w-full flex-col gap-4 px-6 md:px-10">
          {data.linkColumns.map((column, index) => {
            return (
              <div
                key={`column-${index}`}
                className={classNames(
                  'pl-4 transition-all',
                  activeIndexList.includes(index) ? 'bg-white/10 pb-4' : 'bg-white/[0.05]',
                )}
              >
                <button
                  type="button"
                  className="flex w-full items-center justify-between text-left text-base tracking-paragraph text-white"
                  onClick={() => toggle(index)}
                >
                  <span>{column.columnHeading}</span>
                  <span className="size-12 p-3">
                    <ArrowForward
                      role="presentation"
                      className={classNames(
                        'm-auto scale-50 stroke-white transition-transform duration-200',
                        activeIndexList.includes(index) ? '-rotate-90' : 'rotate-90',
                      )}
                    />
                  </span>
                </button>

                <motion.div
                  variants={accordionPanel.variants}
                  initial="inactive"
                  animate={activeIndexList.includes(index) ? 'active' : 'inactive'}
                  className="flex flex-col overflow-hidden"
                >
                  {column.links.map((link, index) => (
                    <div
                      key={`#${column.columnHeading}-link-${index}`}
                      className="py-1 text-sm leading-6 text-white/75"
                    >
                      <Link link={link}>{link.text}</Link>
                    </div>
                  ))}
                </motion.div>
              </div>
            );
          })}
        </div>
      )}

      {data.displayContactDetails && (
        <div className="contact-wrapper flex w-full flex-col items-center gap-4 px-6 md:px-10">
          <p className="text-base tracking-paragraph text-white/90">{data.contactsLabel}</p>

          <Button link={{ href: '/', text: t('general.$getinTouch') }} className="btn primary dark" />

          <a href={`tel:${data.phoneNumber}`} className="text-sm leading-6 text-white/50">
            {data.phoneNumber}
          </a>

          <a href={`mailto:${data.emailAddress}`} className="text-sm leading-6 text-white/50">
            {data.emailAddress}
          </a>
        </div>
      )}

      <hr className="w-full border-dashed border-white/25" />

      {data.displaySocialLinks && (
        <div className="social-wrapper flex w-full items-center justify-center gap-6 px-6 md:px-10">
          {data.socialLinks.map((socialItem, socialIndex) => (
            <div className="text-white/75" key={socialIndex}>
              {socialItem.socialPlatform.toLowerCase() === 'facebook' ? (
                <Link link={socialItem.link} aria-label="Facebook" className="block size-6 [&_path]:fill-current">
                  <Facebook />
                </Link>
              ) : socialItem.socialPlatform.toLowerCase() === 'instagram' ? (
                <Link link={socialItem.link} aria-label="Instagram" className="block size-6 [&_path]:fill-current">
                  <Instagram />
                </Link>
              ) : socialItem.socialPlatform.toLowerCase() === 'linkedin' ? (
                <Link link={socialItem.link} aria-label="Linkedin">
                  <Linkedin />
                </Link>
              ) : socialItem.socialPlatform.toLowerCase() === 'youtube' ? (
                <Link link={socialItem.link} aria-label="Youtube" className="block size-6 [&_path]:fill-current">
                  <Youtube />
                </Link>
              ) : socialItem.socialPlatform.toLowerCase() === 'twitter' ? (
                <Link link={socialItem.link} aria-label="Twitter" className="block size-6 [&_path]:fill-current">
                  <Twitter />
                </Link>
              ) : null}
            </div>
          ))}
        </div>
      )}

      <hr className="w-full border-dashed border-white/25" />

      {data.bottomLinks && (
        <div className="bottom-links-wrapper w-full px-6 md:px-10">
          <div className="mx-auto grid w-[251px] grid-cols-2 justify-items-center gap-x-4 gap-y-2">
            {data.bottomLinks.map((bottomLinkItem, bottomLinkIndex) => (
              <div className="bottom-link-item text-xs leading-paragraph text-white/75" key={bottomLinkIndex}>
                <Link link={bottomLinkItem} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="copyright w-full px-6 md:px-10">
        <p className="text-center text-xs leading-paragraph text-white/75">{data.copyrightText}</p>
      </div>
    </ModuleBase>
  );
};

export default FooterVertical;

FooterVertical.propTypes = {
  data: PropTypes.any.isRequired,
};
