import React, { useRef, useState } from 'react';
import { ModuleBase, Media, Stack, Text, HeadingTag, ResponsiveImage, FullBleedModal, Box } from '@/components';
import PlayIcon from '@/assets/play.svg';
import DownArrow from '@/assets/downArrow.svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const HeroFullBleedLeftModule = ({ data, isAnchorBar }) => {
  const [playing, setPlaying] = useState(false);
  const video = useRef();
  const videoModal = useRef();
  const { t } = useTranslation('common');

  const scrollToComponent = () => {
    const blocks = document.getElementsByClassName('module');
    if (blocks && blocks[1]) {
      blocks[1].scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const preHeading = data.preHeading.split(/\/(.*)/s);

  return (
    <ModuleBase data={data} className="relative h-svh overflow-hidden">
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            ref={video}
            media={data.backgroundMedia}
            widths={{ xs: 425, sm: 768, md: 1024, lg: 1280, xl: 1440, xxl: 2560 }}
            heights={{ xs: 750, sm: null, md: null, lg: null, xl: null, xxl: null }}
            dataNotLazy
            cover
            background={data.backgroundMedia.autoPlay}
            controls={false}
            muted
            className="overlay"
            coverImageProps={{ className: classNames('block absolute inset-0', playing && 'hidden') }}
            preload
            onAutoPlayStarted={() => setPlaying(true)}
            onPlay={() => setPlaying(true)}
          />
        </div>
      )}

      <Stack className="container relative flex h-full flex-col items-start px-6 lg:justify-end lg:px-10" role="main">
        <div
          className={classNames(
            'flex flex-col gap-m pl-0 pt-[160px] sm:w-[495px] sm:pt-[184px] lg:w-[780px] lg:flex-row lg:items-end lg:gap-8 lg:pt-0 xl:ml-[116px]',
            isAnchorBar ? 'lg:pb-[184px]' : 'lg:pb-[144px]',
          )}
        >
          {data.logo && (
            <div className="h-fit w-16 lg:w-20">
              <ResponsiveImage animate image={data.logo} widths={{ xs: 80, md: 80, lg: 80 }} heights={{ xs: null }} />
            </div>
          )}
          <div className="title-wrapper">
            {data.preHeading && (
              <Text className="mb-s font-gotham text-small-paragraph font-n-light uppercase not-italic leading-4 text-white/90">
                {preHeading[0]}
                {preHeading[1] && <span className="font-s-light">/ {preHeading[1]}</span>}
              </Text>
            )}
            {data.headingTitle && (
              <HeadingTag
                motion={{
                  variants: {
                    inactive: {
                      y: 50,
                      opacity: 0,
                    },
                    active: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                        damping: 25,
                        delay: 0.3,
                      },
                    },
                  },
                }}
                data={data.headingTitle}
                className="font-larken text-mobile-large font-normal not-italic leading-mobile-large text-white sm:text-desktop-large sm:leading-desktop-large"
              />
            )}
          </div>
        </div>

        <div
          className="absolute bottom-0 z-[1] hidden h-[78px] w-[116px] cursor-pointer items-center justify-center bg-bright-blue lg:flex xl:ml-[120px]"
          onClick={scrollToComponent}
        >
          <DownArrow className="fill-off-white" />
        </div>

        {(data.backgroundMedia?.vimeoId || data.backgroundMedia.mobileVimeoId) &&
          (data.backgroundMedia.allowFullScreen || (!data.backgroundMedia.autoPlay && !playing)) && (
            <Box
              className={classNames(
                'absolute right-6 sm:right-10',
                isAnchorBar ? 'bottom-9 sm:bottom-[90px] lg:bottom-[120px]' : 'bottom-9 sm:bottom-12 lg:bottom-[60px]',
              )}
            >
              <button
                onClick={() => (!playing ? video.current.play() : videoModal.current.open())}
                className="flex cursor-pointer items-center gap-2.5 self-end py-1 font-gotham text-xs font-normal uppercase leading-6 text-white [&_.btn]:hover:bg-white/75"
              >
                {!data.backgroundMedia.autoPlay && !playing
                  ? t('general.$playVideo')
                  : data.backgroundMedia.allowFullScreen
                    ? t('general.$watchVideo')
                    : null}
                <PlayIcon className="btn icon bg-white/25" role="presentation" />
              </button>
            </Box>
          )}
      </Stack>
      <div className="absolute bottom-10 z-0 w-full border border-dashed border-white/25 lg:bottom-[46px]" />
      <FullBleedModal ref={videoModal}>
        <div className="size-full p-6">
          <Media
            media={{ ...data.backgroundMedia, autoPlay: true, coverImage: null }}
            className="flex items-center"
            controls
            background={false}
          />
        </div>
      </FullBleedModal>
    </ModuleBase>
  );
};

export default HeroFullBleedLeftModule;
