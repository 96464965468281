import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, VimeoPlayer, VideoPlayer, YouTubePlayer, Box } from '@/components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';

const Media = forwardRef(function Media(
  {
    media,
    widths,
    heights,
    preload,
    cdnProps,
    dataNotLazy,
    coverImageProps,
    cover,
    background,
    controls,
    muted,
    className,
    onAutoPlayStarted,
    onPlay,
    motion,
    animate,
    onFullscreenExit,
    hideVideoUntilPlay,
    ...props
  },
  ref,
) {
  const isRobot = useSelector(selectIsRobot);
  const { className: coverImageClassName, ..._coverImageProps } = coverImageProps || {};

  const vimeoPlayer = useRef();
  const videoPlayer = useRef();

  useImperativeHandle(ref, () => {
    return {
      openFullscreen() {
        if (media.vimeoId) {
          vimeoPlayer?.current?.openFullscreen();
        } else if (media.videoFromGallery) {
          videoPlayer?.current?.openFullscreen();
        }
      },
      play() {
        if (media.vimeoId) {
          vimeoPlayer?.current?.play();
        } else if (media.videoFromGallery) {
          videoPlayer?.current?.play();
        }
      },
      pause() {
        if (media.vimeoId) {
          vimeoPlayer?.current?.pause();
        } else if (media.videoFromGallery) {
          videoPlayer?.current?.pause();
        }
      },
      unMute() {
        if (media.vimeoId) {
          vimeoPlayer?.current?.unMute();
        } else if (media.videoFromGallery) {
          videoPlayer?.current?.unMute();
        }
      },
      mute() {
        if (media.vimeoId) {
          vimeoPlayer?.current?.mute();
        } else if (media.videoFromGallery) {
          videoPlayer?.current?.mute();
        }
      },
    };
  }, [media]);

  if (!media) {
    return null;
  }

  return (
    <Box
      motion={motion}
      animate={animate || !!motion}
      className={classNames('relative h-full w-full overflow-hidden', className)}
      {...props}
    >
      {media.coverImage && (
        <ResponsiveImage
          image={media.coverImage}
          widths={widths}
          heights={heights}
          preload={preload}
          cdnProps={cdnProps}
          dataNotLazy={dataNotLazy}
          className={classNames('coverimage', coverImageClassName)}
          {..._coverImageProps}
        />
      )}
      {!isRobot &&
        (media.videoFromGallery || media.vimeoId || media.youtubeId || media.mobileVimeoId) &&
        (media.vimeoId || media.mobileVimeoId ? (
          <VimeoPlayer
            ref={vimeoPlayer}
            media={media}
            cover={cover}
            controls={controls}
            onAutoPlayStarted={onAutoPlayStarted}
            onPlay={onPlay}
            muted={muted}
            onFullscreenExit={onFullscreenExit}
            background={background}
            hideVideoUntilPlay={hideVideoUntilPlay}
          />
        ) : media.videoFromGallery ? (
          <VideoPlayer
            ref={videoPlayer}
            media={media}
            cover={cover}
            controls={controls}
            onAutoPlayStarted={onAutoPlayStarted}
            muted={muted}
          />
        ) : media.youtubeId ? (
          <YouTubePlayer media={media} controls={controls} />
        ) : null)}
    </Box>
  );
});

Media.propTypes = {
  media: PropTypes.object.isRequired,
  widths: PropTypes.object,
  heights: PropTypes.object,
  preload: PropTypes.bool,
  cdnProps: PropTypes.object,
  dataNotLazy: PropTypes.bool,
  coverImageProps: PropTypes.object,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
};

export default Media;
