import React, { useRef } from 'react';
import { EntityCard, CarouselPagination, Stack, Box } from '@/components';
import { listingGridItem } from '@/animations';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { AnimatePresence } from 'framer-motion';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const carouselMotion = {
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: true, amount: 0.5 },
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  },
};

const CarouselFullBleed = ({ cardRow, backgroundColour }) => {
  const carousel = useRef();

  const isLight = !isColorDark(backgroundColour);

  return (
    <div className="relative flex w-full justify-center">
      {cardRow?.map((row, rIndex) => (
        <div className="w-full max-w-[1920px]" key={rIndex}>
          <Stack motion={carouselMotion}>
            <AnimatePresence>
              <Flicking
                ref={carousel}
                panelsPerView={1}
                align="center"
                horizontal
                bound={!['FullBleedLodgeCardRow'].includes(row.moduleName)}
                circular={['FullBleedLodgeCardRow'].includes(row.moduleName)}
                preventDefaultOnDrag
                className="!overflow-visible"
                resizeOnContentsReady
              >
                {row.props.cards.map((card, index) => (
                  <Box key={index} motion={listingGridItem} className="mx-1.5 flex sm:mx-2">
                    <EntityCard
                      data={card}
                      className="mx-2 w-full md:mx-0 [&_.image-wrapper]:cursor-grab"
                      backgroundColour={backgroundColour}
                    />
                  </Box>
                ))}
              </Flicking>
            </AnimatePresence>
          </Stack>
          <div className="flex justify-center pb-5 pt-12 lg:pb-0 lg:pt-4" aria-orientation="horizontal">
            <CarouselPagination
              carousel={carousel}
              hideIfIrrelevant
              dark={isLight}
              className={classNames(
                'w-[148px] lg:h-12 lg:w-[316px] [&_.btn.next]:lg:right-5 [&_.btn.previous]:lg:left-5 [&_.btn]:hidden [&_.btn]:lg:absolute [&_.btn]:lg:top-[calc(50%-48px)] [&_.btn]:lg:z-50 [&_.btn]:lg:block disabled:[&_.btn]:lg:bg-transparent',
                isLight ? '[&_.btn]:lg:bg-white/50' : '[&_.btn]:lg:bg-black/50',
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default CarouselFullBleed;
