import { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';
import { useLayoutEffect, loadLazyImage } from '@/utils';
import { useRouter } from 'next/router';
import { scrollIntoView } from '@/utils';
import SmoothScroller from './SmoothScroller';

const PageContainer = ({ className, ...props }) => {
  const lazyTimer = useRef();
  const isRobot = useSelector(selectIsRobot);
  const router = useRouter();

  useLayoutEffect(() => {
    startToLoadLazyImages();

    //document.querySelector('.scroller>div').setAttribute('role', 'dialog');
    //document.querySelector('.scroller>div').setAttribute('aria-modal', 'true');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      try {
        const element = document.querySelector(window.location.hash);
        if (element) {
          setTimeout(() => {
            scrollIntoView(element);
          }, 300);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [router.asPath]);

  const startToLoadLazyImages = () => {
    clearTimeout(lazyTimer.current);
    lazyTimer.current = setTimeout(() => {
      loadLazyImagesChunk();
    }, 2000);
  };

  // load lazy images
  const loadLazyImagesChunk = () => {
    if (!isRobot) {
      const elList = document.querySelectorAll('.lazy-image:not(.lazy-loaded)');
      if (elList && elList.length > 0) {
        let imgEl = null;
        for (let i = 0; i < 5; i++) {
          loadLazyImage(elList[i]);
          if (elList[i] && i === 4) {
            const media =
              elList[i].children && elList[i].children.length > 0 ? [...elList[i].children, elList[i]] : [elList[i]];

            imgEl = media.find((cEl) => cEl.tagName.toLowerCase() === 'img');
          }
        }
        if (imgEl) {
          if (imgEl.complete) {
            loadLazyImagesChunk();
          } else {
            imgEl.addEventListener('load', () => {
              loadLazyImagesChunk();
            });
          }
        } else {
          loadLazyImagesChunk();
        }
      } else {
        startToLoadLazyImages();
      }
    }
  };

  return (
    <>
      <div className={classnames('page-container will-change-transform', className)} role="contentinfo" {...props} />
      <SmoothScroller />
    </>
  );
};

export default PageContainer;
