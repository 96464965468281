import React from 'react';
import { ModuleBase, HeadingTag, Button, Stack, Text, Box, MapProvider } from '@/components';
import { GoogleMap, Marker } from '@react-google-maps/api';
import DownloadIcon from '@/assets/download.svg';
import classNames from 'classnames';
import { isColorDark, fixColor } from '@/utils';

const MapSimpleModule = ({ data }) => {
  const isText = data.headingTitle?.heading || data.address || data.primaryCta || data.contactDetails?.length > 0;

  const isDark = isColorDark(fixColor(data.backgroundColour));

  const defaultMapCenter = {
    lat: Number(data.latitude.replace(/°.*/, '')),
    lng: Number(data.longitude.replace(/°.*/, '')),
  };

  return (
    <ModuleBase data={data} className="font-gotham">
      <div
        id="map"
        className={classNames(
          'container flex flex-col justify-between gap-10 px-6 sm:py-6 md:items-start md:px-10 lg:py-[26px] lg:pr-[156px]',
          isText && 'lg:flex-row-reverse lg:items-center',
        )}
      >
        {isText && (
          <Stack
            className={classNames(
              'lg:max-w-1/2 w-full md:max-w-[621px] lg:max-w-[432px] lg:pt-10',
              isDark ? 'text-white/75' : 'text-black/75',
            )}
          >
            <HeadingTag
              animate
              data={data.headingTitle}
              className={classNames(
                'mb-8 font-larken text-desktop-medium font-light leading-desktop-medium',
                isDark ? 'text-white' : 'text-black',
              )}
            />
            <Text
              tag="p"
              className={classNames(
                'mb-8 text-base font-n-light tracking-paragraph',
                isDark ? 'text-white' : 'text-black',
              )}
            >
              {data.address}
            </Text>
            {data.contactDetails?.map((contact, index) => (
              <Box key={index} className="mb-8">
                {contact.headingTitle && contact.headingTitle.heading && (
                  <HeadingTag data={contact.headingTitle} className="mb-4 text-base font-n-light tracking-paragraph" />
                )}
                <div
                  dangerouslySetInnerHTML={{ __html: contact.description }}
                  className="richtext-content text-sm font-s-light leading-6"
                />
              </Box>
            ))}
            {data.primaryCta && (
              <Button
                animate
                link={data.primaryCta}
                className={classNames('btn secondary group w-full gap-2.5 md:w-fit', !isDark && 'dark')}
              >
                <DownloadIcon />
              </Button>
            )}
          </Stack>
        )}
        <Stack className="w-full lg:w-[664px]">
          {data.mapTag && (
            <Text
              className={classNames(
                'mb-4 text-xs font-light leading-tiny-paragraph',
                isDark ? 'text-white/50' : 'text-black/50',
              )}
              dangerouslySetInnerHTML={{ __html: data.mapTag }}
            />
          )}

          <Box className="aspect-square w-full lg:mt-[18px] [&_.gm-bundled-control]:!bottom-4 [&_.gm-bundled-control]:!right-4 [&_.gm-bundled-control]:!m-0 [&_.gm-bundled-control_.gmnoprint>div[draggable='false']]:!bg-transparent [&_.gm-bundled-control_.gmnoprint>div[draggable='false']]:!shadow-none [&_.gm-bundled-control_.gmnoprint>div]:!h-[100px] [&_.gm-bundled-control_.gmnoprint>div]:!w-12 [&_.gm-bundled-control_.gmnoprint]:!bottom-0 [&_.gm-bundled-control_.gmnoprint]:!left-[unset] [&_.gm-bundled-control_.gmnoprint]:!right-0 [&_.gm-bundled-control_.gmnoprint]:!top-[unset] [&_.gm-bundled-control_.gmnoprint_.gm-control-active+div]:!hidden [&_.gm-bundled-control_.gmnoprint_.gm-control-active::after]:!block [&_.gm-bundled-control_.gmnoprint_.gm-control-active:first-child::after]:!h-3.5 [&_.gm-bundled-control_.gmnoprint_.gm-control-active:first-child::after]:!content-mapPlus [&_.gm-bundled-control_.gmnoprint_.gm-control-active:first-child]:!mb-1 [&_.gm-bundled-control_.gmnoprint_.gm-control-active:last-child::after]:!-mt-1 [&_.gm-bundled-control_.gmnoprint_.gm-control-active:last-child::after]:!h-3 [&_.gm-bundled-control_.gmnoprint_.gm-control-active:last-child::after]:!content-mapMinus [&_.gm-bundled-control_.gmnoprint_.gm-control-active]:!size-12 [&_.gm-bundled-control_.gmnoprint_.gm-control-active]:!rounded-sm [&_.gm-bundled-control_.gmnoprint_.gm-control-active]:!bg-white [&_.gm-bundled-control_.gmnoprint_.gm-control-active]:!p-3 [&_.gm-bundled-control_.gmnoprint_.gm-control-active_img]:!hidden [&_.gm-fullscreen-control]:!hidden [&_div[style*='display:_inline-flex;']]:!hidden">
            {data.latitude && data.longitude && (
              <MapProvider>
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                  center={defaultMapCenter}
                  zoom={data.zoom}
                  options={{
                    mapTypeControl: false,
                    styles: [
                      {
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#f5f5f5',
                          },
                        ],
                      },
                      {
                        elementType: 'labels.icon',
                        stylers: [
                          {
                            visibility: 'off',
                          },
                        ],
                      },
                      {
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#616161',
                          },
                        ],
                      },
                      {
                        elementType: 'labels.text.stroke',
                        stylers: [
                          {
                            color: '#f5f5f5',
                          },
                        ],
                      },
                      {
                        featureType: 'administrative.land_parcel',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#bdbdbd',
                          },
                        ],
                      },
                      {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#eeeeee',
                          },
                        ],
                      },
                      {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#757575',
                          },
                        ],
                      },
                      {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#e5e5e5',
                          },
                        ],
                      },
                      {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#9e9e9e',
                          },
                        ],
                      },
                      {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#ffffff',
                          },
                        ],
                      },
                      {
                        featureType: 'road.arterial',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#757575',
                          },
                        ],
                      },
                      {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#dadada',
                          },
                        ],
                      },
                      {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#616161',
                          },
                        ],
                      },
                      {
                        featureType: 'road.local',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#9e9e9e',
                          },
                        ],
                      },
                      {
                        featureType: 'transit.line',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#e5e5e5',
                          },
                        ],
                      },
                      {
                        featureType: 'transit.station',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#eeeeee',
                          },
                        ],
                      },
                      {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                          {
                            color: '#c9c9c9',
                          },
                        ],
                      },
                      {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [
                          {
                            color: '#9e9e9e',
                          },
                        ],
                      },
                    ],
                  }}
                >
                  <Marker
                    position={defaultMapCenter}
                    icon={{
                      url: '/mapPin.svg',
                    }}
                  ></Marker>
                </GoogleMap>
              </MapProvider>
            )}
          </Box>
        </Stack>
      </div>
    </ModuleBase>
  );
};

export default MapSimpleModule;
