import React, { useState, useEffect, useRef } from 'react';
import FormControl from './FormControl';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import { useGoogleReCaptcha } from '../recaptcha/UseGoogleRecaptcha';
import { isInViewport, scrollIntoView } from '@/utils';
import { useRouter } from 'next/router';
import { Box, Button } from '..';

const UmbracoForm = ({
  formDefinition,
  successClass,
  submitBtnClass,
  formControlClassName,
  onSubmitSuccessful,
  children,
  ...props
}) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();
  const GAclientId = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();

  useEffect(() => {
    const setGaClientId = () => {
      try {
        // eslint-disable-next-line no-unused-vars
        const reFn = (clientId) => {
          GAclientId.current = clientId;
        };
        /* eslint-disable no-eval */
        eval("gtag('get', 'G-XXXXXX', 'client_id', reFn)");
      } catch (e) {
        // Most likely the error is from ga is not defined. Try until it is
        setTimeout(() => {
          setGaClientId();
        }, 100);
      }
    };
    setGaClientId();
  }, []);

  if (!formDefinition || !formDefinition.pages) {
    return null;
  }

  const initialValues = {};
  formDefinition?.pages.forEach((page) =>
    page.fieldsets.forEach((fieldset) =>
      fieldset.columns.forEach((column) =>
        column.fields.forEach((uField) => {
          if (uField.type.name === 'Multiple choice') {
            initialValues[uField.alias] = [uField.settings.defaultValue];
          } else {
            initialValues[uField.alias] = uField.settings.defaultValue;
          }
        }),
      ),
    ),
  );

  const getActivePageFields = () => {
    const fields = [];
    if (formDefinition?.pages[activePageIndex]) {
      formDefinition?.pages[activePageIndex].fieldsets.forEach((fieldset) =>
        fieldset.columns.forEach((column) => column.fields.forEach((uField) => fields.push(uField))),
      );
    }
    return fields;
  };

  const formDefinitionName = formDefinition.name.replace(/[\W_]+/g, '').toLowerCase();

  const validate = (values) => {
    const errors = {};

    if (formDefinition?.pages[activePageIndex]) {
      const fields = getActivePageFields();

      fields.forEach((field) => {
        if (field.required) {
          if (['checkbox', 'Data Consent', 'Date'].includes(field.type.name)) {
            if (!values[field.alias]) {
              errors[field.alias] = field.requiredErrorMessage;
            }
          } else if (!values[field.alias] || !values[field.alias]?.trim()) {
            errors[field.alias] = field.requiredErrorMessage;
          }
        }
        if (field.pattern) {
          const re = new RegExp(field.pattern);
          if (!re.test(values[field.alias]?.trim())) {
            errors[field.alias] = field.patternInvalidErrorMessage;
          }
        }
      });
    }

    setTimeout(() => {
      const element = form.current.querySelector('.input-group.error');

      if (element && !isInViewport(element)) {
        scrollIntoView(element, 10);
      }
    }, 0);

    return errors;
  };

  const next = (errors, setTouched) => {
    if (Object.keys(errors).length === 0) {
      setActivePageIndex(activePageIndex + 1);
    } else {
      const fields = getActivePageFields();
      const touched = {};
      fields.forEach((field) => {
        touched[field.alias] = true;
      });
      setTouched(touched);
    }
  };

  const prev = () => {
    setActivePageIndex(activePageIndex - 1);
  };

  const submit = async (values) => {
    const isPreview = !!router.query.isPreview;
    await executeRecaptcha('enquiryFormSubmit').then(async (gReCaptchaToken) => {
      if (values.gAclientID !== undefined && GAclientId.current) {
        values.gAclientID = GAclientId.current;
      }
      const submitRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/forms/api/v1/entries/${formDefinition.id}`, {
        method: 'POST',
        body: JSON.stringify({ recaptchaToken: gReCaptchaToken, values }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).catch(console.error);

      if (submitRes.ok) {
        setSubmitted(true);
        onSubmitSuccessful && onSubmitSuccessful();
      } else {
        console.log(`Post Error. HTTP Response Code: ${submitRes?.status}`);
      }
    });
  };

  return (
    <div {...props}>
      {submitted ? (
        <div className={successClass}>{formDefinition.messageOnSubmit}</div>
      ) : (
        <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
          {({ handleSubmit, isSubmitting, validateForm, setTouched }) => (
            <form ref={form} onSubmit={handleSubmit} className="form" id={formDefinition.id}>
              <div className="flex">
                {formDefinition.pages &&
                  formDefinition.pages.map((page, pageIndex) => (
                    <div
                      key={pageIndex}
                      className={classnames(
                        'fieldsets-container w-full flex-col gap-m',
                        activePageIndex === pageIndex ? 'flex' : 'hidden',
                      )}
                    >
                      {page.fieldsets &&
                        page.fieldsets.map((fieldset) => (
                          <fieldset
                            key={fieldset.id}
                            className={classnames('fieldset flex flex-col gap-8 lg:flex-row', fieldset.caption)}
                          >
                            <legend aria-label="form"></legend>
                            {fieldset.columns &&
                              fieldset.columns.map((column, columnIndex) => (
                                <div key={columnIndex} className="fieldset-column flex w-full flex-col">
                                  {column.fields &&
                                    column.fields.map((uField) => {
                                      return (
                                        <Field key={uField.id} name={uField?.alias}>
                                          {({
                                            field, // { name, value, onChange, onBlur }
                                            // form: { touched, errors, dirty }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                            meta,
                                          }) => {
                                            return (
                                              <FormControl
                                                id={uField.id}
                                                type={uField?.type?.name}
                                                label={
                                                  uField?.settings?.showLabel === 'True'
                                                    ? uField?.caption + (uField?.required ? '*' : '')
                                                    : ''
                                                }
                                                error={meta.touched && meta.error}
                                                maxLength={uField?.settings?.maximumLength}
                                                placeholder={
                                                  uField?.settings?.selectPrompt || uField?.settings?.placeholder
                                                }
                                                settings={uField?.settings}
                                                options={uField?.preValues.map(({ caption, value }) => ({
                                                  label: caption,
                                                  value,
                                                }))}
                                                required={uField?.required}
                                                disabled={isSubmitting}
                                                className={formControlClassName}
                                                formDefinitionName={formDefinitionName}
                                                {...field}
                                              />
                                            );
                                          }}
                                        </Field>
                                      );
                                    })}
                                </div>
                              ))}
                          </fieldset>
                        ))}
                    </div>
                  ))}
              </div>
              {children}
              <Box className="buttons-container mt-4 flex gap-4">
                {activePageIndex !== 0 && (
                  <button className="btn secondary" onClick={prev}>
                    {formDefinition.previousLabel}
                  </button>
                )}
                {activePageIndex === formDefinition.pages.length - 1 ? (
                  <Button
                    type="submit"
                    form={formDefinition.id}
                    value="Submit"
                    className={classnames(submitBtnClass || 'btn primary')}
                    text={formDefinition.submitLabel}
                  >
                    {formDefinition.submitLabel}
                  </Button>
                ) : (
                  <div
                    type="button"
                    className="btn primary"
                    onClick={() => validateForm().then((errors) => next(errors, setTouched))}
                  >
                    {formDefinition.nextLabel}
                  </div>
                )}
              </Box>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UmbracoForm;
