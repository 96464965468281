import React from 'react';
import { Link, ModuleBase } from '@/components';

const BreadcrumbsModel = ({ data }) => {
  return (
    <ModuleBase data={data} className="bg-dark-blue py-8 font-gotham text-base font-s-light tracking-paragraph">
      <div className="container flex items-center gap-4 overflow-x-auto px-6 scrollbar-hide md:justify-center md:px-10">
        {data.crumbs?.map((link, index) =>
          link.href ? (
            <React.Fragment key={index}>
              <Link className="whitespace-nowrap text-white/50" link={link} />
              <span className="text-white/50">/</span>
            </React.Fragment>
          ) : (
            <span key={index} className="whitespace-nowrap font-n-light text-white/90">
              {link.text}
            </span>
          ),
        )}
      </div>
    </ModuleBase>
  );
};
export default BreadcrumbsModel;
